import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_COLLECTIONS = 'getCollections'
export const UPDATE_COLLECTION = 'updateCollections'
export const UPDATE_COLLECTION_BILLS = 'updateCollectionsBills'
export const START_STOP_COLLECTION = 'startStopCollection'
export const CREATE_COLLECTION = 'createCollections'
export const CREATE_COLLECTION_BILL_METHOD = 'createCollectionsBillMethod'
export const SET_COLLECTIONS = 'setCollectionsList'
export const GET_COLLECTION_BY_UUID = 'getCollectionByUuid'
export const SET_COLLECTION_BY_UUID = 'setCollectionByUuid'
export const GET_COLLECTION_BY_ID = 'getCollectionById'
export const SET_COLLECTION_BY_ID = 'setCollectionById'

const _this = this

export default {
  namespaced: true,
  state: {
    collectionsList: null,
    collection: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.collectionsList
      if (list) {
        return list
      }
      return []
    },
    activeBankList: state => {
      const currentState = state.bankList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    collectionsList: state => {
      const currentState = state.collectionsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getActiveCollections: state => {
      const currentState = state.collectionsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.record_status === 1)
        }
        return []
      }
      return []
    },
    getInactiveCollections: state => {
      const currentState = state.collectionsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.record_status === 2)
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_COLLECTIONS](state, val) {
      state.collectionsList = val
    },
    [SET_COLLECTION_BY_ID](state, val) {
      state.collection = val
      console.log('-------->', state.collection)
    },
    [SET_COLLECTION_BY_UUID](state, val) {
      state.collection = val
    },
  },
  actions: {
    [CREATE_COLLECTION](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch(err)
            reject(err)
          })
      })
    },
    [CREATE_COLLECTION_BILL_METHOD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/bill-method`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch(err)
            reject(err)
          })
      })
    },
    [UPDATE_COLLECTION](context, payload) {
      const collectionId = payload.id
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${collectionId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch(err)
            reject(err)
          })
      })
    },
    [UPDATE_COLLECTION_BILLS](context, payload) {
      const collectionId = payload.id
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/bill-method/${collectionId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch(err)
            reject(err)
          })
      })
    },
    [START_STOP_COLLECTION](context, payload) {
      const collectionId = payload.id
      const { listType } = payload

      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${collectionId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res).then(() => {
                let recordStatus

                if (listType === 'active') {
                  recordStatus = 1
                } else {
                  recordStatus = 2
                }
                const tempPayload = {
                  skip: 0,
                  limit: 10,
                  listType,
                  record_status: recordStatus,
                  search: {
                    search_enable: false,
                    search_key: '',
                    search_column: '',
                    search_replace_word_enable: true,
                    search_word_replace: ' ',
                    search_word_replace_to: '_',
                  },
                  sort: {
                    parameter_name: 'created_at',
                    sort_type: 'desc',
                  },

                }
                context.dispatch('merchant/getMerchantCollections', tempPayload, { root: true })
              })
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch(err)
            reject(err)
          })
      })
    },
    [GET_COLLECTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/merchant-collection/?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COLLECTIONS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COLLECTION_BY_ID](context, payload) {
      console.log(payload)
      console.log(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${payload}`)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${payload}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COLLECTION_BY_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COLLECTION_BY_UUID](context, payload) {
      console.log(payload)
      console.log(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${payload}/bills`)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${payload}/bills`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COLLECTION_BY_UUID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
