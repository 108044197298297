export const APP_INFO = {
  NAME: 'Lean',
  LOGO_NAME: 'Lean',
  DEBUG_FLAG: process.env.VUE_APP_DEBUG_FLAG,
  DEBUG_FLAG_NAME: process.env.VUE_APP_DEBUG_FLAG_NAME,
  VUE_APP_API_URL: process.env.VUE_APP_API_URL,
  VUE_APP_TOOLS_URL: process.env.VUE_APP_TOOLS_URL,
}

export const APP_SETTINGS = {
  BASE_URL: APP_INFO.VUE_APP_API_URL,
  TOOLS_URL: APP_INFO.VUE_APP_TOOLS_URL,
  API_VERSION_NUM: 'api/v1/',
}

if (APP_INFO.DEBUG_FLAG) {
  console.table(APP_INFO)
  console.table(APP_SETTINGS)
}

export default APP_SETTINGS
