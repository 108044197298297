import _ from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_MERCHANT_TOUR_SETTINGS = 'getMerchantTourSettings'
export const GET_TOUR_SETTINGS_ME = 'getTourSettingsMe'
export const UPDATE_TOUR_SETTINGS = 'updateTourSettings'
export const CREATE_TOUR_SETTINGS = 'createTourSettings'
export const SET_TOUR_SETTINGS = 'setTourSettings'
export const SET_TOUR_SETTINGS_ME = 'setTourSettingsMe'
export const SET_TOUR_SETTINGS_ID = 'setTourSettingsId'

export default {
  namespaced: true,
  state: {
    // tourSettings: {},
    tourSettings: {},
    tourSettingsMe: [],
    tourSettingsId: null,
  },
  getters: {
    getTourSettings: state => {
      const currentState = state.tourSettings
      if (currentState) {
        return currentState
      }
      return []
    },
    getTourSettingsId: state => {
      const currentState = state.tourSettingsId
      if (currentState) {
        return currentState
      }
      return null
    },
    getTourSettingsMe: state => {
      const currentState = state.tourSettingsMe
      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_TOUR_SETTINGS](state, val) {
      state.tourSettings = val
    },
    [SET_TOUR_SETTINGS_ME](state, val) {
      state.tourSettingsMe = val
    },
    [SET_TOUR_SETTINGS_ID](state, val) {
      state.tourSettingsId = val
    },
  },
  actions: {
    [GET_MERCHANT_TOUR_SETTINGS](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}user-tour-setting/merchant-user-tour-setting`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('get tour', res.data.data)
              context.commit(SET_TOUR_SETTINGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_TOUR_SETTINGS_ME](context) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}user-tour-setting/me`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log('get tour me', res.data.data)
              context.commit(SET_TOUR_SETTINGS_ME, res.data.data.setting)
              context.commit(SET_TOUR_SETTINGS_ID, res.data.data.id)
              SystemHelper.successHandler(res)
              resolve(res)
            } else if (res.data.response_code === 3333) {
              SystemHelper.errorHandler(res, true)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_TOUR_SETTINGS](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}user-tour-setting`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_TOUR_SETTINGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_TOUR_SETTINGS](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}user-tour-setting/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_TOUR_SETTINGS, res.data.data.settings)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
