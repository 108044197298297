import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_BUSINESS_OWNER_DETAILS = 'getBusinessOwnerDetails'
export const GET_BUSINESS_OWNER_DETAIL_BY_ID = 'getBusinessOwnerDetailById'
export const CREATE_BUSINESS_OWNER_DETAILS = 'createBusinessOwnerDetails'
export const UPDATE_BUSINESS_OWNER_DETAILS = 'updateBusinessOwnerDetails'
export const DELETE_BUSINESS_OWNER_DETAILS = 'deleteBusinessOwnerDetails'

export const SET_BUSINESS_OWNER_DETAILS_LIST = 'setBusinessOwnerDetailsList'
export const SET_BUSINESS_OWNER_DETAIL = 'setBusinessOwnerDetail'

export default {
  namespaced: true,
  state: {
    businessOwnerDetailsList: null,
    businessOwnerDetail: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.businessOwnerDetailsList
      if (list) {
        return list
      }
      return []
    },
    activeBankList: state => {
      const currentState = state.bankList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    businessOwnerDetailsList: state => {
      const currentState = state.businessOwnerDetailsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_BUSINESS_OWNER_DETAILS_LIST](state, val) {
      state.businessOwnerDetailsList = val
    },
    [SET_BUSINESS_OWNER_DETAIL](state, val) {
      state.businessOwnerDetail = val
    },
  },
  actions: {
    [GET_BUSINESS_OWNER_DETAILS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}business-owner-details`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_BUSINESS_OWNER_DETAILS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_BUSINESS_OWNER_DETAIL_BY_ID](context, userId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}business-owner-details/${userId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_BUSINESS_OWNER_DETAIL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_BUSINESS_OWNER_DETAILS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}business-owner-details`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_BUSINESS_OWNER_DETAILS](context, payload) {
      const { userId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}business-owner-details/${userId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_BUSINESS_OWNER_DETAILS](context, userId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}business-owner-details/${userId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
