import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GENERATE_BILL_ID = 'generateBillId'

export const VERIFY_BANK_ACCOUNT = 'verifyBankAccount'

export const GET_MERCHANT_PAYOUT_RECORD = 'getMerchantPayoutRecord'
export const GET_MERCHANT_VOIDED_SALE_RECORD = 'getMerchantVoidedSalesRecord'
export const SET_MERCHANT_VOIDED_SALE_RECORD = 'setMerchantVoidedSalesRecord'
export const VOID_BILL_SALE = 'voidBillSale'
export const VOID_RECORD_BY_BILL_ID = 'voidRecordByBillId'
export const SET_MERCHANT_PAYOUT_RECORD = 'setMerchantPayoutRecord'
export const GET_MERCHANT_PAYOUT_RECORD_BY_ID = 'getMerchantPayoutRecordById'
export const SET_MERCHANT_PAYOUT_RECORD_BY_ID = 'setMerchantPayoutRecordById'

export const UPGRADE_MERCHANT_PLAN = 'upgradeMerchantPlan'
export const SET_MERCHANT_SUBSCRIPTION_UPGRADE_PLAN = 'setMerchantSubscriptionUpgradePlan'

export const GET_MERCHANT_SUBSCRIPTION_HISTORY = 'getMerchantSubscriptionHistory'
export const SET_MERCHANT_SUBSCRIPTION_HISTORY = 'setMerchantSubscriptionHistory'

export const GET_MERCHANT_AVAILABLE_PLANS = 'getMerchantAvailablePlans'
export const SET_MERCHANT_AVAILABLE_PLANS = 'setMerchantAvailablePlans'

export const GET_MERCHANT_POOL_BY_POOL_TYPE = 'getMerchantPoolByPoolType'
export const SET_MERCHANT_POOL_BY_POOL_TYPE = 'setMerchantPoolByPoolType'

export const GET_MERCHANT_VIRTUAL_ACCOUNTS = 'getMerchantVirtualAccounts'
export const SET_MERCHANT_VIRTUAL_ACCOUNTS = 'setMerchantVirtualAccountList'

export const GET_MERCHANT_POOLS = 'getMerchantPools'
export const SET_MERCHANT_POOLS = 'setMerchantPools'

export const GET_MERCHANT_VIRTUAL_ACCOUNTS_BY_ID = 'getMerchantSelectedVirtualAccount'
export const SET_MERCHANT_VIRTUAL_ACCOUNTS_BY_ID = 'setMerchantSelectedVirtualAccount'

export const GET_MERCHANT_SUCCESS_FIELD = 'getMerchantSuccessField'
export const SET_MERCHANT_SUCCESS_FIELD = 'SetMerchantSuccessField'

export const GET_MERCHANT_SUPPORT_DETAILS = 'getMerchantSupportDetails'
export const SET_MERCHANT_SUPPORT_DETAILS = 'setMerchantSupportDetails'

export const GET_MERCHANT_PAYMENT_SETTINGS = 'getMerchantPaymentSettings'
export const SET_MERCHANT_PAYMENT_SETTINGS = 'setMerchantPaymentSettings'

export const GET_MERCHANT_SHARE_SETTINGS = 'getMerchantShareSettings'
export const SET_MERCHANT_SHARE_SETTINGS = 'setMerchantShareSettings'

export const GET_MERCHANT_CATALOGS = 'getMerchantCatalogs'
export const SET_MERCHANT_CATALOGS = 'setMerchantCatalogs'

export const GET_MERCHANT_COLLECTIONS = 'getMerchantCollections'
export const SET_MERCHANT_COLLECTIONS_ACTIVE = 'setMerchantCollectionsActive'
export const SET_MERCHANT_COLLECTIONS_INACTIVE = 'setMerchantCollectionsInactive'
export const SET_MERCHANT_COLLECTIONS_ALL = 'setMerchantCollectionsAll'
export const CREATE_PAYOUT_INVOICE = 'createPayoutInvoice'
export const CREATE_PAYOUT_INVOICE_REFUND = 'createPayoutInvoiceRefund'

export const GET_MERCHANT_CUSTOMERS = 'getMerchantCustomers'
export const GET_MERCHANT_RECENT_CUSTOMERS = 'getMerchantRecentCustomers'
export const SET_MERCHANT_CUSTOMERS = 'setMerchantCustomers'

export const GET_MERCHANT_CUSTOMER_BILLS_LIST = 'getMerchantCustomerBillsList'
export const SET_MERCHANT_CUSTOMER_BILLS_LIST = 'setMerchantCustomerBillsList'

export const GET_MERCHANT_DISCOUNTS = 'getMerchantDiscounts'
export const SET_MERCHANT_DISCOUNTS = 'setMerchantDiscounts'

export const GET_MERCHANT_PRODUCTS = 'getMerchantProducts'
export const SET_MERCHANT_PRODUCTS = 'setMerchantProducts'

export const GET_MERCHANT_PAYMENT_RECORDS = 'getMerchantPaymentRecords'
export const GET_MERCHANT_RECENT_PAYMENT_RECORDS = 'getMerchantRecentPaymentRecords'
export const SET_MERCHANT_RECENT_PAYMENT_RECORDS = 'setMerchantRecentPaymentRecords'
export const SET_MERCHANT_PAYMENT_RECORDS = 'setMerchantPaymentRecords'

export const GET_MERCHANT_PAYMENT_RECORDS_BY_BILL = 'getMerchantPaymentRecordsByBill'
export const SET_MERCHANT_PAYMENT_RECORDS_BY_BILL = 'setMerchantPaymentRecordsByBill'

export const GET_MERCHANT_PRODUCT_CATEGORIES = 'getMerchantProductCategories'
export const SET_MERCHANT_PRODUCT_CATEGORIES = 'setMerchantProductCategories'

export const GET_MERCHANT_PRODUCT_SUB_CATEGORIES = 'getMerchantProductSubCategories'
export const SET_MERCHANT_PRODUCT_SUB_CATEGORIES = 'setMerchantProductSubCategories'

export const GET_SPECIFIC_CUSTOMER_BILLS = 'getSpecificCustomerBillsList'
export const SET_SPECIFIC_CUSTOMER_BILLS = 'setSpecificCustomerBillsList'

export const GET_MERCHANT_SYSTEM_OPTIONS = 'getMerchantSystemOptions'
export const SET_MERCHANT_SYSTEM_OPTIONS = 'setMerchantSystemOptions'

export const GET_MERCHANT_PAYMENT_UPLOADS = 'getMerchantPaymentUploads'
export const SET_MERCHANT_PAYMENT_UPLOADS = 'setMerchantPaymentUploads'

export const GET_BILLS_LIST_BY_COLLECTION_ID = 'getBillsListByCollectionId'
export const SET_BILLS_LIST_BY_COLLECTION_ID = 'setBillsListByCollectionId'

export const CREATE_PRODUCT_VARIANT = 'createProductVariant'
export const CREATE_BULK_PRODUCT_VARIANT = 'createBulkProductVariant'
export const UPDATE_PRODUCT_VARIANT = 'updateProductVariant'
export const DELETE_PRODUCT_VARIANT = 'deleteProductVariant'
export const GET_PRODUCT_VARIANT_BY_ID = 'geProductVariantById'

export const GET_MERCHANT_PRODUCT_VARIANT = 'getMerchantProductVariant'
export const SET_MERCHANT_PRODUCT_VARIANT = 'setMerchantProductVariant'

export const GET_MERCHANT_SETTLEMENT_LIST = 'getMerchantSettlementList'
export const GET_MERCHANT_INCOMING_SETTLEMENT = 'getMerchantIncomingSettlement'
export const SET_MERCHANT_INCOMING_SETTLEMENT = 'setMerchantIncomingSettlement'
export const SET_MERCHANT_SETTLEMENT_LIST = 'setMerchantSettlementList'
export const GET_MERCHANT_SHIPPING_PROFILE = 'getMerchantShippingProfile'
export const UPDATE_MERCHANT_SHIPPING_PROFILE = 'updateMerchantShippingProfile'
export const GET_MERCHANT_PAYMENT_CALCULATE_FEE = 'getMerchantPaymentCalculateFee'
export const GET_MERCHANT_SETTLEMENT_CALCULATE_FEE = 'getMerchantSettlementCalculateFee'
export const SET_MERCHANT_SHIPPING_PROFILE = 'setMerchantShippingProfile'

export const MERCHANT_CALLBACK_BY_INVOICE_ID = 'triggerMerchantCallbackByInvoiceId'

export default {
  namespaced: true,
  state: {
    merchantSettlementList: null,
    merchantIncomingSettlement: null,
    merchantShippingProfile: null,
    merchantProductVariant: null,
    merchantPayoutRecord: null,
    merchantVoidedSalesRecord: null,
    merchantPayoutRecordById: null,
    merchantPoolList: null,
    merchantVirtualAccountList: null,
    merchantSelectedPool: null,
    merchantSelectedVirtualAccount: null,
    merchantSuccessField: null,
    merchantSupportDetails: null,
    merchantPaymentSettings: null,
    merchantShareSettings: null,
    merchantCatalogs: null,
    merchantAvailablePlan: null,
    merchantSubscriptionHistory: null,
    merchantCollections: {
      all: null,
      active: null,
      inactive: null,
    },
    merchantCustomers: null,
    merchantCustomersBill: null,
    merchantCustomerBillsList: null,
    merchantDiscounts: null,
    merchantProducts: null,
    merchantProductCategories: null,
    merchantProductSubCategories: null,
    specificCustomerBillsList: null,
    merchantPaymentRecords: null,
    merchantRecentPaymentRecords: null,
    merchantPaymentRecordsByBill: null,
    merchantSystemOptions: null,
    merchantPaymentUploads: null,
    merchantBillsByCollectionId: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },

    getMerchantPoolByPoolType: state => {
      const list = state.merchantSelectedPool
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProductVariant: state => {
      const list = state.merchantProductVariant
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantVoidedSalesRecord: state => {
      const list = state.merchantVoidedSalesRecord
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPayoutRecord: state => {
      const list = state.merchantPayoutRecord
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPayoutRecordById: state => {
      const list = state.merchantPayoutRecordById
      if (list) {
        return list.list.data
      }
      return []
    },

    getMerchantSettlementList: state => {
      const list = state.merchantSettlementList
      if (list) {
        // console.log(list.list.data[9].status)
        // const completed = list.list.data.filter(a => a.status === 'COMPLETED')
        // return completed
        return list.list.data
      }
      return []
    },
    getMerchantIncomingSettlement: state => {
      const list = state.merchantIncomingSettlement
      if (list) {
        // console.log(list.list.data[9].status)
        // const completed = list.list.data.filter(a => a.status === 'COMPLETED')
        // return completed
        return list
      }
      return 0
    },
    getMerchantAvailablePlan: state => {
      const list = state.merchantAvailablePlan
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantSubscriptionHistory: state => {
      const list = state.merchantSubscriptionHistory
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPools: state => {
      const list = state.merchantPoolList
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPoolsById: state => id => {
      const list = state.merchantPoolList
      if (list) {
        const specificId = list.list.data.find(a => a.pool_type === id)

        const timeElapsed = Date.now()
        const today = new Date(timeElapsed)

        specificId.lastFetch = today.toISOString()

        return specificId
      }
      return {}
    },
    getMerchantVirtualAccountsList: state => {
      const list = state.merchantVirtualAccountList
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantSelectedVirtualAccount: state => {
      const list = state.merchantSelectedVirtualAccount
      if (list) {
        return list.list.data
      }
      return []
    },
    // OLD
    getCollectionPagination: state => type => {
      let list = state.merchantCollections
      if (type === 'inactive') {
        list = list.inactive
      } else if (type === 'active') {
        list = list.active
      } else {
        list = list.all
      }
      if (list) {
        return list.list
      }
      return []
    },
    getPaginationInfoByStateKey: state => stateKey => {
      console.log(state[stateKey])
      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    getMerchantSuccessFieldById: state => id => {
      const list = state.merchantSuccessField
      if (list) {
        const specificId = list.list.data.find(a => a.id === id)
        return specificId
      }
      return { }
    },
    getMerchantSuccessField: state => {
      const list = state.merchantSuccessField
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantSuccessFieldOptList: state => {
      const list = state.merchantSuccessField
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantShareSettingsOptList: state => {
      const list = state.merchantShareSettings
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantShareSettings: state => {
      const list = state.merchantShareSettings
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantSupportDetailsById: state => id => {
      const list = state.merchantSupportDetails
      if (list) {
        const specificId = list.list.data.find(a => a.id === id)
        return specificId
      }
      return { }
    },
    getMerchantSupportDetails: state => {
      const list = state.merchantSupportDetails
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantSupportDetailsOptList: state => {
      const list = state.merchantSupportDetails
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPaymentSettings: state => {
      const list = state.merchantPaymentSettings
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantPaymentSettingsOptList: state => {
      const list = state.merchantPaymentSettings
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantCatalogs: state => {
      const list = state.merchantCatalogs
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantCollectionOpts: state => {
      let list = state.merchantCollections

      list = list.all
      if (list) {
        return list.list.data.map(item => ({
          name: item.title,
          collection_id: item.id,
        }))
      } return []
    },
    getMerchantCollection: state => type => {
      let list = state.merchantCollections
      if (type === 'inactive') {
        list = list.inactive
      } else if (type === 'active') {
        list = list.active
      } else {
        list = list.all
      }
      if (list) {
        return list.list.data
      }
      return []
    },
    getPaymentRecordsByBill: state => {
      const currentState = state.merchantPaymentRecordsByBill

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getMerchantCustomer: state => {
      const list = state.merchantCustomers
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantCustomers: state => {
      const list = state.merchantCustomers
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantSystemOptions: state => {
      const list = state.merchantSystemOptions
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPaymentRecords: state => {
      const list = state.merchantPaymentRecords
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantRecentPaymentRecords: state => {
      const list = state.merchantRecentPaymentRecords
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProducts: state => {
      const list = state.merchantProducts
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProductsOpts: state => {
      const list = state.merchantProducts

      const opts = list

      console.log('opts', opts)
      if (opts) {
        console.log('opts', opts.list.data)
        if (Array.isArray(opts.list.data) && opts.list.data.length) {
          const newArray = opts.list.data.map(item => ({

            product_id: item.id,
            min_purchase_quantity: item.min_purchase_quantity,
            max_purchase_quantity: item.max_purchase_quantity,
            name: item.title,
            amount: item.price,
            product_variant_enable: item.product_variant_enable,
            product_product_variant: item.product_product_variant,
            //     .map(x => ({
            //   id: x.id,
            //   options_name: x.options_name,
            // })),
          }))
          console.log('newArray', newArray)

          return newArray
        }
      }
      return []
    },
    getMerchantProductCategories: state => {
      const list = state.merchantProductCategories
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProductSubCategories: state => {
      const list = state.merchantProductSubCategories
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProductSubCategoriesOpts: state => {
      const list = state.merchantProductSubCategories
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantProductCategoriesOptsById: state => id => {
      const list = state.merchantProductCategories
      if (list) {
        const specificId = list.list.data
        return specificId
      }
      return []
    },
    getMerchantProductSubCategoriesOptsById: state => id => {
      const list = state.merchantProductSubCategories
      if (list) {
        const specificId = list.list.data.filter(a => a.category_id === id)
        return specificId
      }
      return []
    },
    getMerchantProductCategoriesOpts: state => {
      const list = state.merchantProductCategories
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantCustomerBills: state => {
      const list = state.merchantCustomersBill
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantCustomerBillsList: state => {
      const list = state.merchantCustomerBillsList
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantPaymentUploads: state => {
      const list = state.merchantPaymentUploads
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantDiscounts: state => {
      const list = state.merchantDiscounts
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantShoppingProfile: state => {
      const list = state.merchantShippingProfile
      if (list) {
        return list.list.data[0]
      }
      return { }
    },
    getMerchantBillsByCollectionId: state => {
      const list = state.merchantBillsByCollectionId
      // alert(JSON.stringify(list))
      if (list) {
        return list.list.data
      }
      return []
    },
    specificCustomerBillsList: state => {
      const currentState = state.specificCustomerBillsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_MERCHANT_SETTLEMENT_LIST](state, val) {
      state.merchantSettlementList = val
    },
    [SET_MERCHANT_INCOMING_SETTLEMENT](state, val) {
      state.merchantIncomingSettlement = val
    },
    [SET_MERCHANT_PRODUCT_VARIANT](state, val) {
      state.merchantProductVariant = val
    },
    [SET_MERCHANT_SHIPPING_PROFILE](state, val) {
      state.merchantShippingProfile = val
    },
    [SET_MERCHANT_VOIDED_SALE_RECORD](state, val) {
      state.merchantVoidedSalesRecord = val
    },
    [SET_MERCHANT_AVAILABLE_PLANS](state, val) {
      state.merchantAvailablePlan = val
    },
    [SET_MERCHANT_PAYOUT_RECORD](state, val) {
      state.merchantPayoutRecord = val
    },
    [SET_MERCHANT_PAYOUT_RECORD_BY_ID](state, val) {
      state.merchantPayoutRecordById = val
    },
    [SET_MERCHANT_SUBSCRIPTION_HISTORY](state, val) {
      state.merchantSubscriptionHistory = val
    },
    [SET_MERCHANT_POOL_BY_POOL_TYPE](state, val) {
      state.merchantSelectedPool = val
    },
    [SET_MERCHANT_POOLS](state, val) {
      state.merchantPoolList = val
    },
    [SET_MERCHANT_VIRTUAL_ACCOUNTS](state, val) {
      state.merchantVirtualAccountList = val
    },
    [SET_MERCHANT_VIRTUAL_ACCOUNTS_BY_ID](state, val) {
      state.merchantSelectedVirtualAccount = val
    },
    [SET_MERCHANT_SUCCESS_FIELD](state, val) {
      state.merchantSuccessField = val
    },
    [SET_MERCHANT_SUPPORT_DETAILS](state, val) {
      state.merchantSupportDetails = val
    },
    [SET_MERCHANT_PAYMENT_SETTINGS](state, val) {
      state.merchantPaymentSettings = val
    },
    [SET_MERCHANT_SHARE_SETTINGS](state, val) {
      state.merchantShareSettings = val
    },
    [SET_MERCHANT_CATALOGS](state, val) {
      state.merchantCatalogs = val
    },
    [SET_MERCHANT_COLLECTIONS_ALL](state, val) {
      state.merchantCollections.all = val
    },
    [SET_MERCHANT_COLLECTIONS_ACTIVE](state, val) {
      state.merchantCollections.active = val
    },
    [SET_MERCHANT_COLLECTIONS_INACTIVE](state, val) {
      state.merchantCollections.inactive = val
    },
    [SET_MERCHANT_CUSTOMERS](state, val) {
      state.merchantCustomers = val
    },
    [SET_MERCHANT_PRODUCTS](state, val) {
      state.merchantProducts = val
    },
    [SET_MERCHANT_PRODUCT_CATEGORIES](state, val) {
      state.merchantProductCategories = val
    },
    [SET_MERCHANT_PRODUCT_SUB_CATEGORIES](state, val) {
      state.merchantProductSubCategories = val
    },
    [SET_MERCHANT_CUSTOMER_BILLS_LIST](state, val) {
      state.merchantCustomerBillsList = val
    },
    [SET_SPECIFIC_CUSTOMER_BILLS](state, val) {
      state.specificCustomerBillsList = val
    },
    [SET_MERCHANT_PAYMENT_RECORDS](state, val) {
      state.merchantPaymentRecords = val
    },
    [SET_MERCHANT_RECENT_PAYMENT_RECORDS](state, val) {
      state.merchantRecentPaymentRecords = val
    },
    [SET_MERCHANT_PAYMENT_RECORDS_BY_BILL](state, val) {
      state.merchantPaymentRecordsByBill = val
    },
    [SET_MERCHANT_SYSTEM_OPTIONS](state, val) {
      state.merchantSystemOptions = val
    },
    [SET_MERCHANT_PAYMENT_UPLOADS](state, val) {
      state.merchantPaymentUploads = val
    },
    [SET_BILLS_LIST_BY_COLLECTION_ID](state, val) {
      state.merchantBillsByCollectionId = val
    },
  },
  actions: {
    // STOREFRONT
    [CREATE_PRODUCT_VARIANT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-variants`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_BULK_PRODUCT_VARIANT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-variants/bulk/create-product-variant`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_PRODUCT_VARIANT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-variants/${payload.productVariantId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_PRODUCT_VARIANT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-variants/${payload.productVariantId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [VERIFY_BANK_ACCOUNT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/verify-bank-account`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // move later
    [CREATE_PAYOUT_INVOICE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-payout-invoice`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res, false)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // PYOUT REFUND INVOICE
    [CREATE_PAYOUT_INVOICE_REFUND](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-void-sale-payout-refund`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res, false)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_POOL_BY_POOL_TYPE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}pools/transaction-pool/list-${payload.id}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_POOL_BY_POOL_TYPE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SETTLEMENT_LIST](context, payload) {
      const payloadOv = {
        ...payload,
        // search: {
        //   search_enable: true,
        //   search_key: 'COMPLETED',
        //   search_column: 'status',
        //   search_replace_word_enable: true,
        //   search_word_replace: ' ',
        //   search_word_replace_to: '_',
        // },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-records/merchant-settlement-records?skip=${payload.skip}&limit=${payload.limit}`, payloadOv)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_SETTLEMENT_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_MERCHANT_INCOMING_SETTLEMENT](context, payload) {
      const payloadOv = {
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}settlement-records/incoming-settlement`, payloadOv)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_INCOMING_SETTLEMENT, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [UPDATE_MERCHANT_SHIPPING_PROFILE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipping-profiles/${payload.shippingProfileId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_CALCULATE_FEE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/merchant/calculate-fee`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_MERCHANT_SETTLEMENT_CALCULATE_FEE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/merchant/calculate-settlement-fee`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SHIPPING_PROFILE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipping-profiles/merchant-shipping-profiles?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_SHIPPING_PROFILE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYOUT_RECORD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payout-records/merchant-payout-records?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_PAYOUT_RECORD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // [GET_MERCHANT_PAYOUT_RECORD_BY_ID](context, payload) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payout-records/merchant-payout-records?skip=0&limit=100?skip=${payload.skip}&limit=${payload.limit}`, payload)
    //       .then(res => {
    //         if (res.data.response_code === 2000) {
    //           // console.log(res.data)
    //           useJwt.setToken(res.data.token)
    //           context.commit(SET_MERCHANT_PAYOUT_RECORD_BY_ID, res.data.data)
    //           SystemHelper.successHandler(res)
    //           resolve(res)
    //         } else {
    //           SystemHelper.errorHandler(res)
    //           resolve(res)
    //         }
    //       })
    //       .catch(err => {
    //         SystemHelper.handleCatch()
    //         reject(err)
    //       })
    //   })
    // },
    [GENERATE_BILL_ID](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}jwt/generate/bill-id`)
          .then(res => {
            if (res.data.response_code === 2000) {
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SUBSCRIPTION_HISTORY](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/merchant-subscription-history?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_SUBSCRIPTION_HISTORY, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_VOIDED_SALE_RECORD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}void-sale-records/merchant-void-sale-records?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_VOIDED_SALE_RECORD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_AVAILABLE_PLANS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/merchant/list?skip=0&limit=100`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_AVAILABLE_PLANS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPGRADE_MERCHANT_PLAN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/upgrade-plan?plan_id=${payload.planId}&time_period=${payload.timePeriod}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [VOID_BILL_SALE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}void-sale-records/merchant/void-record`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [VOID_RECORD_BY_BILL_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}void-sale-records/merchant/void-record-based-bill-id`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_POOLS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}pools/merchant/list?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_POOLS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_VIRTUAL_ACCOUNTS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}virtual-accounts/merchant-virtual-accounts?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_VIRTUAL_ACCOUNTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_VIRTUAL_ACCOUNTS_BY_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}virtual-accounts/transaction-list/${payload.id}?start=${payload.skip}&length=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_VIRTUAL_ACCOUNTS_BY_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_CUSTOMER_BILLS_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/merchant-customer-bill?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              // console.log('getMerchantCustomerBillsList', res.data.data)
              context.commit(SET_MERCHANT_CUSTOMER_BILLS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_RECORDS_BY_BILL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/bill-invoice/?skip=${payload.skip}&limit=${payload.limit}&bill_invoice=${payload.bill_invoice}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_PAYMENT_RECORDS_BY_BILL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_RECORDS_BY_BILL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/bill-invoice/?skip=${payload.skip}&limit=${payload.limit}&bill_invoice=${payload.bill_invoice}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_PAYMENT_RECORDS_BY_BILL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_UPLOADS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-upload-files/merchant-upload-bill-excel?skip=${payload.skip}&limit=${payload.limit}&collection_id=${payload.collection_id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              console.log('GET_MERCHANT_PAYMENT_UPLOADS', res.data.data)
              context.commit(SET_MERCHANT_PAYMENT_UPLOADS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SYSTEM_OPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/merchant-system-options?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              console.log('GET_MERCHANT_SYSTEM_OPTIONS', res.data.data)
              context.commit(SET_MERCHANT_SYSTEM_OPTIONS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_CUSTOMERS](context, payload) {
      // console.log('getMerchantCustomers')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customers/merchant-customer?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('getMerchantCustomers', res.data.data)
              context.commit(SET_MERCHANT_CUSTOMERS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_RECENT_CUSTOMERS](context, payload) {
      // console.log('getMerchantCustomers')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customers/merchant-customer-v2?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('getMerchantCustomers', res.data.data)
              context.commit(SET_MERCHANT_CUSTOMERS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_RECORDS](context, payload) {
      // console.log('GET_MERCHANT_PAYMENT_RECORDS')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/merchant-payment-records?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('SET_MERCHANT_PAYMENT_RECORDS', res.data.data)
              context.commit(SET_MERCHANT_PAYMENT_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_RECENT_PAYMENT_RECORDS](context, payload) {
      // console.log('GET_MERCHANT_PAYMENT_RECORDS')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-records/merchant-recent-payment-records-details?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('SET_MERCHANT_PAYMENT_RECORDS', res.data.data)
              context.commit(SET_MERCHANT_RECENT_PAYMENT_RECORDS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_SPECIFIC_CUSTOMER_BILLS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/get-based-customer/?customer_id=${payload.id}&skip=${payload.skip}&limit=${payload.limit}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SPECIFIC_CUSTOMER_BILLS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_BILLS_LIST_BY_COLLECTION_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/get-based-collection/${payload.id}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_BILLS_LIST_BY_COLLECTION_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_DISCOUNTS](context, payload) {
      console.log('getMerchantDiscounts')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}discounts/merchant-discount?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantDiscounts', res.data.data)
              context.commit(SET_MERCHANT_DISCOUNTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PRODUCTS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}products/merchant-product?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('SET_MERCHANT_PRODUCTS', res.data.data)
              context.commit(SET_MERCHANT_PRODUCTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PRODUCT_CATEGORIES](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-categories/merchant-product-category?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('SET_MERCHANT_PRODUCT_CATEGORIES', res.data.data)
              context.commit(SET_MERCHANT_PRODUCT_CATEGORIES, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PRODUCT_SUB_CATEGORIES](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/product-subcategory/merchant-product-subcategory
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-subcategory/merchant-product-subcategory?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('SET_MERCHANT_PRODUCT_CATEGORIES', res.data.data)
              context.commit(SET_MERCHANT_PRODUCT_SUB_CATEGORIES, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_COLLECTIONS](context, payload) {
      // console.log('getMerchantCollections')
      const { listType } = payload
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/merchant-collection/?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              // console.log('getMerchantCollections', res.data.data)
              if (listType === 'active') {
                context.commit(SET_MERCHANT_COLLECTIONS_ACTIVE, res.data.data)
              } if (listType === 'inactive') {
                context.commit(SET_MERCHANT_COLLECTIONS_INACTIVE, res.data.data)
              } else {
                context.commit(SET_MERCHANT_COLLECTIONS_ALL, res.data.data)
              }
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_CATALOGS](context, payload) {
      console.log('getMerchantCatalogs')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}catalogs/merchant-catalogs?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantCatalogs', res.data.data)
              context.commit(SET_MERCHANT_CATALOGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SUCCESS_FIELD](context, payload) {
      console.log('getMerchantSuccessField')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}success-field/merchant-success-fields?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('getMerchantSuccessField', res.data.data)
              context.commit(SET_MERCHANT_SUCCESS_FIELD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SUPPORT_DETAILS](context, payload) {
      console.log('merchant-support-details')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}support-details/merchant-support-details?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('merchant-support-details', res.data.data)
              context.commit(SET_MERCHANT_SUPPORT_DETAILS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SYSTEM_OPTIONS](context, payload) {
      console.log('GET_MERCHANT_SYSTEM_OPTIONS')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}system-options/merchant-system-options?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('GET_MERCHANT_SYSTEM_OPTIONS', res.data.data)
              context.commit(SET_MERCHANT_SYSTEM_OPTIONS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PAYMENT_SETTINGS](context, payload) {
      console.log('merchant-payment-settings')
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-settings/merchant-payment-settings?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('merchant-payment-settings', res.data.data)
              context.commit(SET_MERCHANT_PAYMENT_SETTINGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SHARE_SETTINGS](context, payload) {
      console.log('merchant-share-settings')
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}share-settings/merchant-share-settings?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              console.log('merchant-share-settings', res.data.data)
              context.commit(SET_MERCHANT_SHARE_SETTINGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [MERCHANT_CALLBACK_BY_INVOICE_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/merchant-callback-data/send-data-based-invoice-id`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
