import { groupBy } from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import SystemHelper from '@/common/systemHelper'

export const ADD_PRODUCT_TO_CART = 'addProductToCart'
export const REMOVE_PRODUCT_FROM_CART = 'removeProductFromCart'
export const UPDATE_PRODUCT_QUANTITY_FROM_CART = 'updateProductQuantityFromCart'

export const GET_STORE_BY_UNIQUE_ID = 'getStoreByUniqueId'

export const SET_ADD_PRODUCT_TO_CART = 'setAddProductToCart'
export const SET_REMOVE_PRODUCT_FROM_CART = 'setRemoveProductFromCart'
export const SET_PRODUCT_QUANTITY_FROM_CART = 'setRemoveProductFromCart'

export const SET_STORE_BY_UNIQUE_ID = 'setStoreByUniqueId'

const getDefaultState = () => ({
  cartItems: [],
  storeFront: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getUserCart: state => {
      const currentState = state.cartItems
      if (currentState) {
        const list = currentState

        return list
      }
      return {}
    },
    // getStoreObject: state => {
    //   const currentState = state.storeFront
    //   return currentState
    // },
    getStoreObject: state => uniqueId => {
      const currentState = state.storeFront.filter(obj => obj.uniqueId !== uniqueId)
      return currentState[0]
    },
    removeItemFormCart: state => id => {
      const currentState = state.cartItems
      if (currentState) {
        const list = currentState

        return list
      }
      return {}
    },
    getUserCartProductListSubmissionObj: state => {
      const currentState = state.cartItems
      const groupedProduct = groupBy(currentState, item => item.id)

      const finalProductList = []

      for (const prod in groupedProduct) {
        console.log(groupedProduct[prod])

        groupedProduct[prod].forEach(function (a) {
          if (!this[a.id]) {
            this[a.id] = {
              id: a.id,
              purchaseQuantity: 0,
              total_amount: 0,
              amount: a.amount,
            }
            finalProductList.push(this[a.id])
          }
          this[a.id].purchaseQuantity += a.purchaseQuantity
          console.log(this)
          this[a.id].total_amount = a.amount * a.purchaseQuantity
        }, Object.create(null))
      }

      const finalFinalProductList = finalProductList.map(({
        purchaseQuantity: quantity,
        ...rest
      }) => ({
        quantity,
        ...rest,
      }))
      console.log(finalFinalProductList)
      return finalFinalProductList
    },
    getUserCartTotalItem: state => {
      const currentState = state.cartItems
      if (currentState) {
        return currentState.length
      } return 0
    },
    getUserCartTotalPayable: state => {
      const currentState = state.cartItems
      if (currentState) {
        const groupedProduct = groupBy(currentState, item => item.id)

        const finalProductList = []

        for (const prod in groupedProduct) {
          console.log(groupedProduct[prod])

          groupedProduct[prod].forEach(function (a) {
            if (!this[a.id]) {
              this[a.id] = {
                id: a.id,
                purchaseQuantity: 0,
                total_amount: 0,
                amount: a.amount,
                productInfo: a.productInfo,
              }
              finalProductList.push(this[a.id])
            }
            this[a.id].purchaseQuantity += a.purchaseQuantity
            console.log(this)
            this[a.id].total_amount += a.total_amount
          }, Object.create(null))
        }

        const finalFinalProductList = finalProductList.map(({
          purchaseQuantity: quantity,
          ...rest
        }) => ({
          quantity,
          ...rest,
        }))
        console.log(finalFinalProductList)

        return finalFinalProductList.reduce((accumulator, object) => accumulator + (object.total_amount * object.quantity), 0)
      }
      return {}
    },
  },
  mutations: {
    [SET_ADD_PRODUCT_TO_CART](state, val) {
      const { cartItems } = state
      cartItems.push(val)
    },
    [SET_REMOVE_PRODUCT_FROM_CART](state, id) {
      state.cartItems = state.cartItems.filter(obj => obj.id !== id)
    },
    [SET_STORE_BY_UNIQUE_ID](state, payload) {
      const uniqueId = payload
      // alert(JSON.stringify(payload.data.data.unique_id))

      let { storeFront } = state
      storeFront.push(payload.data.data)

      storeFront = groupBy(storeFront, item => item.unique_id)
    },
  },
  actions: {
    [ADD_PRODUCT_TO_CART](context, payload) {
      context.commit(SET_ADD_PRODUCT_TO_CART, payload)
    },
    [REMOVE_PRODUCT_FROM_CART](context, payload) {
      context.commit(SET_REMOVE_PRODUCT_FROM_CART, payload)
    },
    [GET_STORE_BY_UNIQUE_ID](context, uniqueId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}storespublic/${uniqueId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_STORE_BY_UNIQUE_ID, res)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              if (res.data.response_code === 5011) {

              } else {
                SystemHelper.errorHandler(res)
              }
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },

}
