import _ from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const DOWNLOAD_PAYOUT_REPORT_RANGE = 'downloadPayoutReportRange'
export const GET_PAYOUT_LIST_SERVICES = 'getPayoutListServices'
export const SET_PAYOUT_LIST_SERVICES = 'getPayoutListServices'

export default {
  namespaced: true,
  state: {
    payoutListServices: [],
  },
  getters: {
    getPayoutListServices: state => {
      const currentState = state.payoutListServices
      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.record_status_id === 1)
        }
        return []
      }
      return []
    },
    getPayoutListServicesOpts: state => {
      const currentState = state.payoutListServices
      if (currentState) {
        const opts = currentState

        if (opts && opts.list) {
          if (Array.isArray(opts.list.data) && opts.list.data.length) {
            const newArray = opts.list.data.map(item => ({
              label: item.name, value: item.unique_reference, payout_service_id: item.payout_service_id,
            }))

            const sortedObj = _.sortBy(newArray,
              [function (o) { return o.label }])
            return sortedObj
          }
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_PAYOUT_LIST_SERVICES](state, val) {
      state.payoutListServices = val
    },
  },
  actions: {
    [DOWNLOAD_PAYOUT_REPORT_RANGE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}reports/merchant/payout-report-range-date?start_date=${payload.start_date}&end_date=${payload.end_date}`, payload, { responseType: 'blob' })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PAYOUT_LIST_SERVICES](context, payload) {
      if (!payload.bankTypeId) {
        // eslint-disable-next-line no-param-reassign
        payload.bankTypeId = 1
      }

      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/list-payout-services/${payload.bankTypeId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYOUT_LIST_SERVICES, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
