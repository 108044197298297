import axiosIns from "@/libs/axios";
import { APP_SETTINGS } from "@/common/config";

export const GET_STORE = "getStore";
export const GET_STORE_PRODUCTS = "getStoreProducts";
export const GET_STORE_PRODUCT_INFO = "getStoreProductInfo";
export const GET_STORE_PRODUCT_INFO_FOR_CART = "getStoreProductInfoForCart";
export const ADD_TO_CART = "addToCart";
export const UPDATE_CART = "updateCart";
export const DO_UPDATE_CART_QUANTITY = "doUpdateCartQuantity";
export const DO_REMOVE_PRODUCT_FROM_CART = "doRemoveProductFromCart";
export const DO_CLEAR_CART_FOR_STORE = "doClearCartForStore";
export const GET_PAYMENT_SERVICES_MOBILE = "getPaymentServicesMobile";
export const CREATE_BILL = "createBill";
export const DO_PAYMENT = "doPayment";
export const GET_PAYMENT_STATUS = "getPaymentStatus";
export const GET_DELIVERY_LIST = "getDeliveryList";
export const GET_SYSTEM_CONSTANTS = "getStorefrontSystemConstants";
export const GET_SYSTEM_CONSTANTS_PUBLIC = "getStorefrontSystemConstantsPublic";
export const UPDATE_SLIDER = "updateSlider";

export const SET_STORE = "setStore";
export const SET_STORE_PRODUCTS = "setStoreProducts";
export const SET_STORE_PRODUCT_INFO = "setStoreProductInfo";
export const SET_CART = "setCart";
export const SET_UPDATE_CART = "setUpdateCart";
export const UPDATE_CART_QUANTITY = "updateCarQuantity";
export const REMOVE_PRODUCT_FROM_CART = "removeProductFromCart";
export const CLEAR_CART_FOR_STORE = "clearCartForStore";
export const SET_PAYMENT_SERVICES_MOBILE = "setPaymentServicesMobile";
export const SET_BILL = "setBill";
export const SET_PAYMENT_STATUS = "setPaymentStatus";
export const SET_DELIVERY_LIST = "setDeliveryList";
export const SET_SYSTEM_CONSTANTS = "setSystemConstant";
export const SET_SYSTEM_CONSTANTS_PUBLIC = "setSystemConstantPublic";
export const SET_SLIDER = "setSlider";

const getDefaultState = () => ({
	store: {},
	products: [],
	productInfo: {},

	cart: [],

	paymentServices: {},
	bill: {},
	paymentStatus: {},
	deliveryList: [],

	systemConstants: {},
	systemConstantsPublic: {},

	slider: "",
});

export default {
	namescaped: true,
	state: getDefaultState(),
	getters: {
		storefrontTotalCart: (state) => (storeID) => {
			let storeCart = state.cart.find((e) => e.storeID == storeID);
			if (!storeCart) return 0;

			let amount = 0;
			storeCart.items.forEach((item) => {
				amount += parseFloat((item.amount * item.quantity).toFixed(2));
			});
			return amount;
		},
		storefrontTotalProductWeight: (state) => (storeID) => {
			let storeCart = state.cart.find((e) => e.storeID == storeID);
			if (!storeCart) return null;

			let total = 0;
			storeCart.items.forEach((item) => {
				if (!item.data?.weight) total += 1;
				else total += item.data.weight;
			});
			return total;
		},
		storefrontGetProduct:
			(state) =>
			(storeID, productID, variantID = null) => {
				let storeCart = state.cart.find((e) => e.storeID == storeID);
				if (!storeCart) return {};

				let product = storeCart.items.find((e) => {
					if (variantID) return e.data.id == productID && e.variant.id == variantID;
					return e.data.id == productID && Object.keys(e.variant).length == 0;
				});

				return product ? product : {};
			},
	},
	actions: {
		async [GET_STORE](context, uniqueId) {
			try {
				var { data } = await axiosIns.get(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + `stores/public/store-${uniqueId}`);
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_STORE, data.data);
			}

			return Promise.resolve(data);
		},
		async [GET_STORE_PRODUCTS](context, uniqueId) {
			try {
				var { data } = await axiosIns.get(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + `stores/public/product-${uniqueId}`);
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_STORE_PRODUCTS, data.data);
			}

			return Promise.resolve(data);
		},
		async [GET_STORE_PRODUCT_INFO](context, code) {
			try {
				var { data } = await axiosIns.get(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + `products/product-details/${code}`);
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_STORE_PRODUCT_INFO, data.data);
			}

			return Promise.resolve(data);
		},
		async [GET_STORE_PRODUCT_INFO_FOR_CART](context, code) {
			try {
				var { data } = await axiosIns.get(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + `products/product-details/${code}`);
			} catch (err) {
				return Promise.reject(err);
			}

			return Promise.resolve(data);
		},
		[ADD_TO_CART](context, cart) {
			context.commit(SET_CART, cart);
		},
		[UPDATE_CART](context, cart) {
			context.commit(SET_UPDATE_CART, cart);
		},
		[DO_UPDATE_CART_QUANTITY](context, data) {
			context.commit(UPDATE_CART_QUANTITY, data);
		},
		[DO_REMOVE_PRODUCT_FROM_CART](context, data) {
			context.commit(REMOVE_PRODUCT_FROM_CART, data);
		},
		[DO_CLEAR_CART_FOR_STORE](context, storeID) {
			context.commit(CLEAR_CART_FOR_STORE, storeID);
		},
		async [GET_PAYMENT_SERVICES_MOBILE](context, payload) {
			try {
				var { data } = await axiosIns.get(
					APP_SETTINGS.BASE_URL +
						APP_SETTINGS.API_VERSION_NUM +
						`payment-services/switch/selected-list-payment-services/${payload.bank_type}?_invoice_uuid=${payload.uuid}`
				);
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_PAYMENT_SERVICES_MOBILE, data.data.list.data);
			}

			return Promise.resolve(data);
		},
		async [CREATE_BILL](context, payload) {
			try {
				var { data } = await axiosIns.post(
					APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + `customer-bills/public/customer-bill-method`,
					payload
				);
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_BILL, data.data);
			}

			return Promise.resolve(data);
		},
		async [DO_PAYMENT](context, payload) {
			try {
				var { data } = await axiosIns.post(
					APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + `payment-services/switch/payment-bill/_invoice?_invoice=${payload.invoiceNo}`,
					payload
				);
			} catch (err) {
				return Promise.reject(err);
			}

			return Promise.resolve(data);
		},
		async [GET_PAYMENT_STATUS](context, invoiceNo) {
			try {
				var { data } = await axiosIns.get(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + `customer-bills/${invoiceNo}/leanpay-bills`);
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_PAYMENT_STATUS, data.data);
			}

			return Promise.resolve(data);
		},
		async [GET_DELIVERY_LIST](context, payload) {
			try {
				var { data } = await axiosIns.post(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + "parcel-asia/merchant/delivery-price-check", payload);
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_DELIVERY_LIST, data.data);
			}

			return Promise.resolve(data);
		},
		async [GET_SYSTEM_CONSTANTS](context) {
			try {
				var { data } = await axiosIns.get(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + "onboard/constant/system-constant");
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_SYSTEM_CONSTANTS, data.data);
			}

			return Promise.resolve(data);
		},
		async [GET_SYSTEM_CONSTANTS_PUBLIC](context) {
			try {
				var { data } = await axiosIns.post(APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM + "portal/public/portal-constant");
			} catch (err) {
				return Promise.reject(err);
			}

			if (data.response_code == 2000) {
				context.commit(SET_SYSTEM_CONSTANTS_PUBLIC, data.data);
			}

			return Promise.resolve(data);
		},
		[UPDATE_SLIDER](context, value) {
			context.commit(SET_SLIDER, value);
		},
	},
	mutations: {
		[SET_STORE](state, data) {
			state.store = structuredClone(data);
		},
		[SET_STORE_PRODUCTS](state, data) {
			state.products = structuredClone(data);
		},
		[SET_STORE_PRODUCT_INFO](state, data) {
			state.productInfo = structuredClone(data);
		},
		[SET_CART](state, data) {
			let store = state.cart.find((e) => e.storeID == data.storeID);
			if (!store) {
				data.items.forEach((e) => {
					e.amount = e.variant.custom_pricing_enable ? e.variant.custom_pricing_price : e.data.price;
					e.total_amount = parseFloat((e.amount * e.quantity).toFixed(2));
				});
				state.cart.push(data);
			} else {
				let storeIndex = state.cart.findIndex((e) => e.storeID == data.storeID);
				data.items.forEach((d) => {
					let itemIndex = state.cart[storeIndex].items.findIndex((e) => {
						if (Object.keys(d.variant).length) return e.data.id == d.data.id && e.variant.id == d.variant.id;

						return e.data.id == d.data.id && Object.keys(e.variant).length == 0;
					});
					let amount = d.variant.custom_pricing_enable ? d.variant.custom_pricing_price : d.data.price;

					if (itemIndex == -1) {
						d.amount = amount;
						d.total_amount = parseFloat((d.amount * d.quantity).toFixed(2));
						state.cart[storeIndex].items.push(d);
					} else {
						let newItem = state.cart[storeIndex].items[itemIndex];
						newItem.amount = amount;
						newItem.quantity += d.quantity;
						newItem.variant = Object.keys(d.variant).length ? d.variant : {};
						newItem.total_amount = parseFloat((amount * newItem.quantity).toFixed(2));

						state.cart[storeIndex].items[itemIndex] = structuredClone(newItem);
					}
				});
			}
		},
		[SET_UPDATE_CART](state, data) {
			let storeIndex = state.cart.findIndex((e) => e.storeID == data.storeID);
			console.log("storeIndex", storeIndex);
			if (storeIndex == -1) return;

			let itemIndex = state.cart[storeIndex].items.findIndex((e) => {
				if (data.variantID) return e.data.id == data.productID && e.variant.id == data.variantID;
				return e.data.id == data.productID && Object.keys(e.variant).length == 0;
			});
			console.log("itemIndex", itemIndex);
			if (itemIndex == -1) return;

			// console.log("SET_UPDATE_CART", state.cart[storeIndex].items[itemIndex]);

			state.cart[storeIndex].items[itemIndex] = {
				...state.cart[storeIndex].items[itemIndex],
				...data.changes,
			};
		},
		[UPDATE_CART_QUANTITY](state, data) {
			let storeIndex = state.cart.findIndex((e) => e.storeID == data.storeID);
			if (storeIndex != -1) {
				if (data.variantID) {
					var itemIndex = state.cart[storeIndex].items.findIndex((e) => e.data.id == data.productID && e.variant.id == data.variantID);
				} else {
					var itemIndex = state.cart[storeIndex].items.findIndex((e) => e.data.id == data.productID && Object.keys(e.variant).length == 0);
				}

				if (itemIndex != -1) {
					state.cart[storeIndex].items[itemIndex].quantity = data.quantity;

					let amount = state.cart[storeIndex].items[itemIndex].amount;
					state.cart[storeIndex].items[itemIndex].total_amount = parseFloat((amount * data.quantity).toFixed(2));
				}
			}
		},
		[REMOVE_PRODUCT_FROM_CART](state, data) {
			let storeIndex = state.cart.findIndex((e) => e.storeID == data.storeID);
			if (storeIndex != -1) {
				if (data.variantID) {
					var itemIndex = state.cart[storeIndex].items.findIndex((e) => e.data.id == data.productID && e.variant.id == data.variantID);
				} else {
					var itemIndex = state.cart[storeIndex].items.findIndex((e) => e.data.id == data.productID && Object.keys(e.variant).length == 0);
				}

				if (itemIndex != -1) {
					delete state.cart[storeIndex].items.splice(itemIndex, 1);
				}
			}
		},
		[CLEAR_CART_FOR_STORE](state, storeID) {
			let storeIndex = state.cart.findIndex((e) => e.storeID == storeID);
			if (storeIndex != -1) state.cart[storeIndex].items = [];
		},
		[SET_PAYMENT_SERVICES_MOBILE](state, data) {
			state.paymentServices = structuredClone(data);
		},
		[SET_BILL](state, data) {
			state.bill = structuredClone(data);
		},
		[SET_PAYMENT_STATUS](state, data) {
			state.paymentStatus = structuredClone(data);
		},
		[SET_DELIVERY_LIST](state, data) {
			state.deliveryList = structuredClone(data);
		},
		[SET_SYSTEM_CONSTANTS](state, data) {
			state.systemConstants = structuredClone(data);
		},
		[SET_SYSTEM_CONSTANTS_PUBLIC](state, data) {
			state.systemConstantsPublic = structuredClone(data);
		},
		[SET_SLIDER](state, data) {
			state.slider = data;
		},
	},
};
