import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_PRODUCT_TAGS = 'getProductTags'
export const GET_MERCHANT_PRODUCT_TAGS = 'getMerchantProductTags'
export const GET_PRODUCT_TAG_BY_ID = 'getProductTagById'
export const CREATE_PRODUCT_TAGS = 'createProductTags'
export const UPDATE_PRODUCT_TAGS = 'updateProductTags'
export const DELETE_PRODUCT_TAGS = 'deleteProductTags'

export const SET_PRODUCT_TAGS_LIST = 'setProductTagsList'
export const SET_PRODUCT_TAG = 'setProductTag'

export default {
  namespaced: true,
  state: {
    productTagsList: null,
    productTag: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.productTagsList
      if (list) {
        return list
      }
      return []
    },
    activeProductTagList: state => {
      const currentState = state.productTagsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },

    getSelectedProductTag: state => {
      const currentState = state.productTag

      if (currentState) {
        const list = currentState.list.data
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    productTagsOpt: state => {
      const currentState = state.productTagsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    productTagsList: state => {
      const currentState = state.productTagsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_PRODUCT_TAGS_LIST](state, val) {
      state.productTagsList = val
    },
    [SET_PRODUCT_TAG](state, val) {
      state.productTag = val
    },
  },
  actions: {
    [GET_PRODUCT_TAGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-tags`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PRODUCT_TAGS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_PRODUCT_TAGS](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/product-tags/merchant-product-tags
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-tags/merchant-product-tags?skip=0&limit=1000`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PRODUCT_TAGS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PRODUCT_TAG_BY_ID](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-tags/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PRODUCT_TAG, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_PRODUCT_TAGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-tags`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_PRODUCT_TAGS](context, payload) {
      console.log(payload)
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-tags/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_PRODUCT_TAGS](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}product-tags/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
