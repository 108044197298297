import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_SUBSCRIPTIONS = 'getSubscriptions'
export const GET_SYSTEM_PAYMENT_RATE = 'getSystemPaymentRate'
export const GET_SUBSCRIPTIONS_DETAILS_ME = 'getSubscriptionsDetailsMe'
export const GET_PENDING_SUBSCRIPTION_PAYMENT = 'getPendingSubscriptionPayment'
export const GET_SUBSCRIPTION_BY_ID = 'getSubscriptionById'
export const CREATE_SUBSCRIPTIONS = 'createSubscriptions'
export const UPDATE_SUBSCRIPTIONS = 'updateSubscriptions'
export const DELETE_SUBSCRIPTIONS = 'deleteSubscriptions'

export const SET_SUBSCRIPTIONS_LIST = 'setSubscriptionsList'
export const SET_PENDING_SUBSCRIPTION_PAYMENT = 'setPendingSubscriptionPayment'
export const SET_SUBSCRIPTIONS_DETAILS_ME = 'setSubscriptionsDetailsMe'
export const SET_SUBSCRIPTIONS_DETAILS_ADV_ME = 'setSubscriptionsDetailsAdvMe'
export const SET_SUBSCRIPTION = 'setSubscription'
export const SET_SYSTEM_PAYMENT_RATE = 'setSystemPaymentRate'

export default {
  namespaced: true,
  state: {
    systemPaymentRateList: null,
    subscriptionsList: null,
    subscriptionDetailsMe: null,
    subscriptionDetailsAdvMe: null,
    subscription: null,
    pendingSubscriptionPayment: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.subscriptionsList
      if (list) {
        return list
      }
      return []
    },
    activeSubscriptionList: state => {
      const currentState = state.subscriptionsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },

    getSelectedSubscription: state => {
      const currentState = state.subscription

      if (currentState) {
        const list = currentState.list.data
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    subscriptionsList: state => {
      const currentState = state.subscriptionsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getPendingSubscriptionPayment: state => {
      const currentState = state.pendingSubscriptionPayment

      if (currentState) {
        return currentState.list.data
      }
      return []
    },
    getMySubscriptionDetails: state => {
      const currentState = state.subscriptionDetailsMe

      if (currentState) {
        return currentState
      }
      return {}
    },
    getSystemPaymentRate: state => {
      const currentState = state.systemPaymentRateList

      if (currentState) {
        return currentState
      }
      return {}
    },
    getSystemPaymentRateByPlanName: state => name => {
      const currentState = state.systemPaymentRateList

      if (currentState) {
        return currentState
      }
      return {}
    },
    getSubscriptionDetailsAdvMe: state => {
      const currentState = state.subscriptionDetailsAdvMe

      if (currentState) {
        return currentState
      }
      return {}
    },
  },
  mutations: {
    [SET_SUBSCRIPTIONS_LIST](state, val) {
      state.subscriptionsList = val
    },
    [SET_SUBSCRIPTION](state, val) {
      state.subscription = val
    },
    [SET_SYSTEM_PAYMENT_RATE](state, val) {
      state.systemPaymentRateList = val
    },
    [SET_PENDING_SUBSCRIPTION_PAYMENT](state, val) {
      state.pendingSubscriptionPayment = val
    },
    [SET_SUBSCRIPTIONS_DETAILS_ME](state, val) {
      state.subscriptionDetailsMe = val
    },
    [SET_SUBSCRIPTIONS_DETAILS_ADV_ME](state, val) {
      state.subscriptionDetailsAdvMe = val
    },
  },
  actions: {
    [GET_SUBSCRIPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTIONS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SYSTEM_PAYMENT_RATE](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/onboard/constant/system-payment-rate
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}onboard/constant/system-payment-rate`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_SYSTEM_PAYMENT_RATE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SUBSCRIPTIONS_DETAILS_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/details/me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTIONS_DETAILS_ME, res.data.data)
              context.commit(SET_SUBSCRIPTIONS_DETAILS_ADV_ME, res.data.data.Subscription.advanced_setting)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SUBSCRIPTION_BY_ID](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTION, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PENDING_SUBSCRIPTION_PAYMENT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-payment/pending-subscription-payment?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_PENDING_SUBSCRIPTION_PAYMENT, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SUBSCRIPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_SUBSCRIPTIONS](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_SUBSCRIPTIONS](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscriptions/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
