import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_SHARE_SETTINGS = 'getShareSettings'
export const GET_SHARE_SETTING_BY_ID = 'getShareSettingById'
export const CREATE_SHARE_SETTINGS = 'createShareSettings'
export const UPDATE_SHARE_SETTINGS = 'updateShareSettings'
export const DELETE_SHARE_SETTINGS = 'deleteShareSettings'

export const SET_SHARE_SETTINGS_LIST = 'setShareSettingsList'
export const SET_SHARE_SETTING = 'setShareSetting'

export default {
  namespaced: true,
  state: {
    shareSettingsList: null,
    shareSetting: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.shareSettingsList
      if (list) {
        return list
      }
      return []
    },
    activeShareSettingList: state => {
      const currentState = state.shareSettingsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },

    getSelectedShareSetting: state => {
      const currentState = state.shareSetting

      if (currentState) {
        const list = currentState.list.data
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    shareSettingsList: state => {
      const currentState = state.shareSettingsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_SHARE_SETTINGS_LIST](state, val) {
      state.shareSettingsList = val
    },
    [SET_SHARE_SETTING](state, val) {
      state.shareSetting = val
    },
  },
  actions: {
    [GET_SHARE_SETTINGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}share-settings`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SHARE_SETTINGS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SHARE_SETTING_BY_ID](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}share-settings/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SHARE_SETTING, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SHARE_SETTINGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}share-settings`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_SHARE_SETTINGS](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}share-settings/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_SHARE_SETTINGS](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}share-settings/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
