import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_DEV_MERCHANT_API_LIST = 'getDevMerchantAPIList'

export const SET_DEV_MERCHANT_API_LIST = 'setDevMerchantAPIList'
export default {
  namespaced: true,
  state: {
    merchantDevAPIList: null,
  },
  getters: {
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    merchantDevAPIList: state => {
      const currentState = state.merchantDevAPIList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_DEV_MERCHANT_API_LIST](state, val) {
      state.merchantDevAPIList = val
    },
  },
  actions: {
    [GET_DEV_MERCHANT_API_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/merchant/list`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_DEV_MERCHANT_API_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },

}
