import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_SHIPPING_PROFILES = 'getShippingProfiles'
export const GET_SHIPPING_PROFILE_BY_ID = 'getShippingProfileById'
export const CREATE_SHIPPING_PROFILES = 'createShippingProfiles'
export const UPDATE_SHIPPING_PROFILES = 'updateShippingProfiles'
export const DELETE_SHIPPING_PROFILES = 'deleteShippingProfiles'

export const SET_SHIPPING_PROFILES_LIST = 'setShippingProfilesList'
export const SET_SHIPPING_PROFILE = 'setShippingProfile'

export default {
  namespaced: true,
  state: {
    shippingProfilesList: null,
    shippingProfile: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.shippingProfilesList
      if (list) {
        return list
      }
      return []
    },
    activeShippingProfileList: state => {
      const currentState = state.shippingProfilesList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },

    getSelectedShippingProfile: state => {
      const currentState = state.shippingProfile

      if (currentState) {
        const list = currentState.list.data
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    shippingProfilesList: state => {
      const currentState = state.shippingProfilesList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_SHIPPING_PROFILES_LIST](state, val) {
      state.shippingProfilesList = val
    },
    [SET_SHIPPING_PROFILE](state, val) {
      state.shippingProfile = val
    },
  },
  actions: {
    [GET_SHIPPING_PROFILES](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipping-profiles`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SHIPPING_PROFILES_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SHIPPING_PROFILE_BY_ID](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipping-profiles/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SHIPPING_PROFILE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SHIPPING_PROFILES](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipping-profiles`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_SHIPPING_PROFILES](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipping-profiles/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_SHIPPING_PROFILES](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipping-profiles/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
