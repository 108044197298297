import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_MERCHANT_DISCOUNTS = 'getMerchantDiscounts'
export const SET_MERCHANT_DISCOUNTS = 'setMerchantDiscountsList'

export default {
  namespaced: true,
  state: {
    merchantDiscountsList: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.merchantDiscountsList
      if (list) {
        return list.list
      }
      return []
    },
    activeDiscountsList: state => {
      const currentState = state.merchantDiscountsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    discountsList: state => {
      const currentState = state.merchantDiscountsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_MERCHANT_DISCOUNTS](state, val) {
      state.merchantDiscountsList = val
    },
  },
  actions: {
    [GET_MERCHANT_DISCOUNTS](context, payload) {
      console.log('GET_MERCHANT_DISCOUNTS', payload)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}discounts/merchant-discount?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_DISCOUNTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
