import * as Sentry from '@sentry/browser'
import { APP_SETTINGS } from '@/common/config'

import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_ACCOUNTS = 'getAccounts'
export const GET_ACCOUNT = 'getAccount'
export const CREATE_ACCOUNT = 'createAccount'
export const GET_ACCOUNTS_ME = 'getAccountMe'
export const UPDATE_ACCOUNT = 'updateAccount'
export const GET_ACCOUNT_USERS = 'getAccountUsers'
export const GET_ACCOUNT_USERS_ME = 'getAccountUsersMe'
export const ADD_USER_TO_ACCOUNT = 'addUserToAccount'
export const MERCHANT_SWITCH_ACCOUNT = 'merchantSwitchAccount'
export const UPDATE_SETTLEMENT_CHANNEL = 'updateSettlementChannel'

export const UPDATE_PAYOUT_SETTINGS = 'updatePayoutSettings'

export const GET_MERCHANT_NOTIFICATION_SETTINGS = 'getMerchantNotificationSettings'
export const UPDATE_MERCHANT_NOTIFICATION_SETTINGS = 'updateMerchantNotificationSettings'
export const CREATE_MERCHANT_NOTIFICATION_SETTINGS = 'createMerchantNotificationSettings'

export const SET_ACCOUNTS_LIST = 'setAccountList'
export const SET_ACCOUNTS_ME = 'setAccountMe'
export const SET_ACCOUNT = 'setAccount'
export const SET_ACCOUNT_USERS_LIST = 'setAccountUserList'
export const SET_ACCOUNT_USERS_ME_LIST = 'setAccountUserList'
export const SET_MERCHANT_NOTIFICATION_SETTINGS = 'setMerchantNotificationSettings'

export default {
  namespaced: true,
  state: {
    accountList: null,
    account: null,
    accountMe: null,
    accountUserList: null,
    merchantNotificationSettings: null,
  },
  getters: {
    getAccountId() {
      return parseInt(useJwt.getAccountId(), 10)
    },
    getCurrentAccountObj() {
      return JSON.parse(useJwt.getCurrentAccountObj())
    },
    getPaginationInfo: state => {
      const list = state.accountMe
      if (list) {
        return list
      }
      return []
    },
    getCurrentAccountMe: state => {
      const currentState = state.accountMe

      if (currentState) {
        const list = currentState
        return list
      }
      return {}
    },
    getMerchantNotificationSettings: state => {
      const currentState = state.merchantNotificationSettings

      if (currentState) {
        const list = currentState
        return list
      }
      return {}
    },
    getSubscriptionPaymentChannel: state => {
      const currentState = state.accountMe

      if (currentState && currentState.subscription_plan) {
        const list = currentState.subscription_plan
        if (list) {
          return {
            fpx_enable: list.fpx_enable,
            crypto_enable: list.crypto_enable,
            bnpl_enable: list.bnpl_enable,
            credit_card_enable: list.credit_card_enable,
            ewallet_enable: list.ewallet_enable,
            paypal_enable: list.paypal_enable,
          }
        }
        return {
          fpx_enable: false,
          crypto_enable: false,
          bnpl_enable: false,
          credit_card_enable: false,
          ewallet_enable: false,
          paypal_enable: false,
        }
      }
      return {
        fpx_enable: false,
        crypto_enable: false,
        bnpl_enable: false,
        credit_card_enable: false,
        ewallet_enable: false,
        paypal_enable: false,
      }
    },
    activeAccountList: state => {
      const currentState = state.accountMe

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    accountList: state => {
      const currentState = state.accountMe

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_ACCOUNTS_LIST](state, val) {
      state.accountList = val
    },
    [SET_ACCOUNT](state, val) {
      state.account = val
    },
    [SET_ACCOUNTS_ME](state, val) {
      state.accountMe = val
    },
    [SET_ACCOUNT_USERS_LIST](state, val) {
      state.accountUserList = val
    },
    [SET_ACCOUNT_USERS_ME_LIST](state, val) {
      state.accountUserMeList = val
    },
    [SET_MERCHANT_NOTIFICATION_SETTINGS](state, val) {
      state.merchantNotificationSettings = val
    },
  },
  actions: {
    [GET_ACCOUNTS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log('getaccount', res.data.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNTS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNTS_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/me?skip=0&limit=100`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)

              context.commit(SET_ACCOUNTS_ME, res.data.data.list.data[0])
              const userMeData = res.data.data.list.data[0]

              Sentry.setUser(userMeData)

              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNT_USERS](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}/users`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNT_USERS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ACCOUNT_USERS_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/users/me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_ACCOUNT_USERS_ME_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_NOTIFICATION_SETTINGS](context, payload) {
      // /api/v1/notification-settings/merchant-notification-settings
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}notification-settings/merchant-notification-settings?limit=${payload.limit}&skip=${payload.skip}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_NOTIFICATION_SETTINGS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_NOTIFICATION_SETTINGS](context, payload) {
      // /api/v1/notification-settings/merchant-notification-settings
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}notification-settings/${payload.notificationId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_MERCHANT_NOTIFICATION_SETTINGS](context, payload) {
      // /api/v1/notification-settings/merchant-notification-settings
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}notification-settings`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [ADD_USER_TO_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/${accountId}/users`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [MERCHANT_SWITCH_ACCOUNT](context, payload) {
      const { accountId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/merchant/${accountId}/switch`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_ACCOUNT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_SETTLEMENT_CHANNEL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/merchant/settlement-channel-toggle?channel_id=${payload.channel_id}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_PAYOUT_SETTINGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}accounts/merchant/confident-ratio-setting`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
