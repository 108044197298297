import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import SystemHelper from '@/common/systemHelper'

export const GET_CUSTOMER_BILLS_BY_INVOICE_ID = 'getCustomerBillsByInvoiceId'
export const SET_CUSTOMER_BILLS_BY_INVOICE_ID = 'setCustomerBillsByInvoiceId'
export const SET_ENDPOINT_ERROR = 'setEndpointError'
export const UPDATE_ENDPOINT_ERROR = 'updateEndpointError'
export const CLEAR_ENDPOINT_ERROR = 'clearEndpointError'

export default {
  namespaced: true,
  state: {
    customerBillsByInvoiceId: null,
    endpointError: null,
  },
  getters: {
    getEndpointError: state => {
      const currentState = state.endpointError

      if (currentState) {
        const error = currentState
        return error
      }
      return null
    },
    getCustomerBillsByInvoiceId: state => {
      const currentState = state.customerBillsByInvoiceId

      if (currentState) {
        const list = currentState
        return list
      }
      return null
    },
  },
  mutations: {
    [SET_CUSTOMER_BILLS_BY_INVOICE_ID](state, val) {
      state.customerBillsByInvoiceId = val
    },
    [SET_ENDPOINT_ERROR](state, val) {
      state.endpointError = val
    },
  },
  actions: {
    [UPDATE_ENDPOINT_ERROR](context, payload) {
      context.commit(SET_ENDPOINT_ERROR, payload)
    },
    [CLEAR_ENDPOINT_ERROR](context) {
      context.commit(SET_ENDPOINT_ERROR, null)
    },
    [GET_CUSTOMER_BILLS_BY_INVOICE_ID](context, payload) {
      const { invoiceId } = payload
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/${invoiceId}/leanpay-bills`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              context.commit(SET_CUSTOMER_BILLS_BY_INVOICE_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res, true)
              resolve(res)
            }
          })
          .catch(err => {
            // console.log(err.toJSON())
            context.commit(SET_ENDPOINT_ERROR, { endpoint: 'getCustomerBillsByInvoiceId', errorObj: err.toJSON() })
            // if (err.response) {
            //   console.log(err.response)
            //   // The client was given an error response (5xx, 4xx)
            // } else if (err.request) {
            //   console.log(err.request)
            //   // The client never received a response, and the request was never left
            // } else {
            //   // Anything else
            //   console.log('Error', err.message)
            // }
            // SystemHelper.handleCatch(err)
            // reject(err)
          })
      })
    },
  },
}
