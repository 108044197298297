import { pick, omit } from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import SystemHelper from '@/common/systemHelper'

export const GET_STORE_BY_UNIQUE_ID = 'getStoreByUniqueId'
export const GET_PRODUCT_STORE_LIST = 'getProductStoreList'
export const GET_PRODUCT_DETAIL = 'getProductDetail'
export const SET_PRODUCT_DETAIL = 'setProductDetail'
export const SET_PRODUCT_STORE_LIST = 'setProductStoreList'
export const SET_STORE_BY_UNIQUE_ID = 'setStoreByUniqueId'
export const SET_STORE_ACCOUNT = 'setStoreAccount'
export const SET_STORE_COLLECTION_INFO = 'setStoreCollectionInfo'

const getDefaultState = () => ({
  cartItems: [],
  storeFrontCollectionInfo: [],
  storeFrontAccount: [],
  storeFront: [],
  storeProducts: [],
  productDetail: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getStoreFrontData: state => {
      const currentState = state.storeFront
      if (currentState) {
        return omit(currentState, ['store_collection'])
      } return {}
    },
    getStoreCollectionInfo: state => {
      const currentState = state.storeFrontCollectionInfo
      if (currentState) {
        return omit(currentState, ['collection_account'])
      } return {}
    },
    getStoreCollectionAccount: state => {
      const currentState = state.storeFrontAccount
      if (currentState) {
        return currentState
      } return {}
    },
    getStoreProducts: state => {
      const currentState = state.storeProducts
      if (currentState) {
        return currentState
      } return {}
    },
  },
  mutations: {
    [SET_STORE_BY_UNIQUE_ID](state, payload) {
      state.storeFront = payload
    },
    [SET_STORE_ACCOUNT](state, payload) {
      state.storeFrontAccount = payload
    },
    [SET_STORE_COLLECTION_INFO](state, payload) {
      state.storeFrontCollectionInfo = payload
    },
    [SET_PRODUCT_STORE_LIST](state, payload) {
      state.storeProducts = payload
    },
    [SET_PRODUCT_DETAIL](state, payload) {
      state.productDetail = payload
    },
  },
  actions: {
    [GET_STORE_BY_UNIQUE_ID](context, uniqueId) {
      // /api/v1/stores/public/store-{_unique_id}
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/public/store-${uniqueId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_STORE_BY_UNIQUE_ID, res.data.data)
              context.commit(SET_STORE_ACCOUNT, res.data.data.store_collection.collection_account)
              context.commit(SET_STORE_COLLECTION_INFO, res.data.data.store_collection)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              if (res.data.response_code === 5011) {

              } else {
                SystemHelper.errorHandler(res)
              }
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PRODUCT_STORE_LIST](context, uniqueId) {
      // /api/v1/stores/public/product-{_unique_id}
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/public/product-${uniqueId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res)
              context.commit(SET_PRODUCT_STORE_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              if (res.data.response_code === 5011) {

              } else {
                SystemHelper.errorHandler(res)
              }
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PRODUCT_DETAIL](context, code) {
      // /api/v1/stores/public/product-{_unique_id}
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/public/product-details-${code}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res)
              context.commit(SET_PRODUCT_DETAIL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              if (res.data.response_code === 5011) {

              } else {
                SystemHelper.errorHandler(res)
              }
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },

}
