import _ from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_RECENT_MASTER_POOL_BALANCE = 'getRecentMasterPoolBalance'
export const SET_RECENT_MASTER_POOL_BALANCE = 'setRecentMasterPoolBalance'
export const GET_MASTER_POOL_LATEST_BALANCE = 'getMasterPoolLatestBalance'
export const SET_MASTER_POOL_LATEST_BALANCE = 'setMasterPoolLatestBalance'

export default {
  namespaced: true,
  state: {
    masterPoolBalance: null,
    masterPoolLatestBalance: null,
  },
  getters: {
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfo: state => {
      const list = state.masterPoolBalance
      if (list) {
        return list.list
      }
      return []
    },
    getMasterPoolBalanceList: state => {
      const currentState = state.masterPoolBalance

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getMasterPoolLatestBalance: state => {
      const currentState = state.masterPoolLatestBalance

      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_RECENT_MASTER_POOL_BALANCE](state, val) {
      state.masterPoolBalance = val
    },
    [SET_MASTER_POOL_LATEST_BALANCE](state, val) {
      state.masterPoolLatestBalance = val
    },
  },
  actions: {
    [GET_RECENT_MASTER_POOL_BALANCE](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}master-pool-balance/merchant-recent-master-pool-balances?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              context.commit(SET_RECENT_MASTER_POOL_BALANCE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MASTER_POOL_LATEST_BALANCE](context) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}master-pool-balance/latest-balance`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              context.commit(SET_MASTER_POOL_LATEST_BALANCE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
