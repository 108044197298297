import { DateTime } from 'luxon'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const SET_API_REPORT_GENERATE_REPORT_DAY = 'setApiReportGenerateReportDay'
export const SET_API_REPORT_GENERATE_REPORT_MONTH = 'setApiReportGenerateReportMonth'
export const SET_API_REPORT_GENERATE_REPORT_YEAR = 'setApiReportGenerateReportYear'
export const SET_API_REPORT_GENERATE_REPORT_WEEK = 'setApiReportGenerateReportWeek'
export const SET_API_REPORT_DASHBOARD = 'setApiReportDashboard'

export const GET_API_REPORT_GENERATE_REPORT_DAY = 'getApiReportGenerateReportDay'
export const GET_API_REPORT_GENERATE_REPORT_MONTH = 'getApiReportGenerateReportMonth'
export const GET_API_REPORT_GENERATE_REPORT_YEAR = 'getApiReportGenerateReportYear'
export const GET_API_REPORT_GENERATE_REPORT_WEEK = 'getApiReportGenerateReportWeek'

export const GET_MERCHANT_API_LIST = 'getMerchantAPIList'
export const GET_MERCHANT_API_DASHBOARD = 'getMerchantAPIDashboard'
export const GET_MERCHANT_API_BY_ID = 'getMerchantAPIById'
export const SET_MERCHANT_API_LIST = 'setMerchantAPIList'
export const UPDATE_MERCHANT_API = 'updateMerchantAPI'
export const CREATE_MERCHANT_API = 'createMerchantAPI'

export const GET_MERCHANT_IP_WHITELIST = 'getMerchantIpWhitelist'
export const SET_MERCHANT_IP_WHITELIST = 'setMerchantIpWhitelist'
export const CREATE_MERCHANT_IP_WHITELIST = 'createMerchantIpWhitelist'
export const UPDATE_MERCHANT_IP_WHITELIST = 'updateMerchantIpWhitelist'

export default {
  namespaced: true,
  state: {
    merchantAPIList: null,
    merchantApiReportData: null,
    merchantApiReportDay: null,
    merchantApiReportMonth: null,
    merchantApiReportYear: null,
    merchantApiReportWeek: null,
    merchantApiReportDashboard: null,
    merchantIPWhitelistList: null,
  },
  getters: {
    // NEW
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },

    activeChartData: state => type => {
      let currentState
      if (type === 'day') {
        currentState = state.merchantApiReportDay
      }
      if (type === 'month') {
        currentState = state.merchantApiReportMonth
      }
      if (type === 'year') {
        currentState = state.merchantApiReportYear
      }
      if (type === 'week') {
        currentState = state.merchantApiReportWeek
      }

      if (currentState) {
        return currentState
      }
      return {}
    },
    merchantApiReportData: state => {
      const currentState = state.merchantApiReportData

      if (currentState) {
        return currentState
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.merchantAPIList
      if (list) {
        return list.list
      }
      return []
    },
    activeMerchantAPIList: state => {
      const currentState = state.merchantAPIList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    merchantAPIList: state => {
      const currentState = state.merchantAPIList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_API_REPORT_GENERATE_REPORT_DAY](state, val) {
      state.merchantApiReportDay = val
    },
    [SET_API_REPORT_GENERATE_REPORT_MONTH](state, val) {
      state.merchantApiReportMonth = val
    },
    [SET_API_REPORT_GENERATE_REPORT_YEAR](state, val) {
      state.merchantApiReportYear = val
    },
    [SET_API_REPORT_GENERATE_REPORT_WEEK](state, val) {
      state.merchantApiReportWeek = val
    },
    [SET_API_REPORT_DASHBOARD](state, val) {
      state.merchantApiReportData = val
    },
    [SET_MERCHANT_API_LIST](state, val) {
      state.merchantAPIList = val
    },
    [SET_MERCHANT_IP_WHITELIST](state, val) {
      state.merchantIPWhitelistList = val
    },
  },
  actions: {
    [GET_MERCHANT_API_DASHBOARD](context, payload) {
      console.log('GET_MERCHANT_API_DASHBOARD ', payload)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/auth-key/merchant-dashboard/${payload.uuid}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_API_REPORT_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_API_REPORT_GENERATE_REPORT_DAY](context, payload) {
      if (!payload.date) {
        payload.date = DateTime.now().toFormat('dd-MM-yyyy')
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/auth-key/${payload.uuid}/dashboard-generate-report/day-${payload.date}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_API_REPORT_GENERATE_REPORT_DAY, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_API_REPORT_GENERATE_REPORT_MONTH](context, payload) {
      if (!payload.year) {
        payload.year = DateTime.now().year
      }
      if (!payload.month) {
        payload.month = DateTime.now().month
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/auth-key/${payload.uuid}/dashboard-generate-report/month-${payload.month}?skip=${payload.skip}&limit=${payload.limit}&year=${payload.year}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_API_REPORT_GENERATE_REPORT_MONTH, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_API_REPORT_GENERATE_REPORT_WEEK](context, payload) {
      if (!payload.date) {
        payload.date = DateTime.now().toFormat('dd-MM-yyyy')
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/auth-key/${payload.uuid}/dashboard-generate-report/last-week-${payload.date}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_API_REPORT_GENERATE_REPORT_WEEK, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_API_REPORT_GENERATE_REPORT_YEAR](context, payload) {
      if (!payload.year) {
        payload.year = DateTime.now().year
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/auth-key/${payload.uuid}/dashboard-generate-report/${payload.year}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_API_REPORT_GENERATE_REPORT_YEAR, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_API_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/merchant/list`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_API_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_IP_WHITELIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}white-list-address/me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_IP_WHITELIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_IP_WHITELIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}white-list-address/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              // context.commit(SET_MERCHANT_IP_WHITELIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [CREATE_MERCHANT_IP_WHITELIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}white-list-address`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              // context.commit(SET_MERCHANT_IP_WHITELIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_API_BY_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/${payload}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_API](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api/${payload.api_id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_MERCHANT_API](context, payload) {
      return new Promise((resolve, reject) => {
        // alert('payload')
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}merchant-api`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
