import _ from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_MERCHANT_SELF_SETTLEMENT_RECORD = 'getMerchantSelfSettlementRecord'
export const SET_MERCHANT_SELF_SETTLEMENT_RECORD = 'setMerchantSelfSettlementRecord'
export const CREATE_SELF_SETTLEMENT_INVOICE = 'createSelfSettlementInvoice'

export default {
  namespaced: true,
  state: {
    merchantSelfSettlementRecord: null,
    merchantSelfSettlementRecordById: null,
  },
  getters: {

    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },

    getMerchantSelfSettlementRecord: state => {
      const list = state.merchantSelfSettlementRecord
      if (list) {
        return list.list.data
      }
      return []
    },
    getMerchantSelfSettlementRecordById: state => {
      const list = state.merchantSelfSettlementRecordById
      if (list) {
        return list.list.data
      }
      return []
    },
  },
  mutations: {

    [SET_MERCHANT_SELF_SETTLEMENT_RECORD](state, val) {
      state.merchantSelfSettlementRecord = val
    },
  },
  actions: {
    [CREATE_SELF_SETTLEMENT_INVOICE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-self-settlement-invoice`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res, false)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SELF_SETTLEMENT_RECORD](context, payload) {
      return new Promise((resolve, reject) => {
        // https://stag-api.leanpay.my/api/v1/own-settlement-records/merchant-own-settlement-records?skip=0&limit=100
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}own-settlement-records/merchant-own-settlement-records?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_SELF_SETTLEMENT_RECORD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
