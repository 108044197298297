import { DateTime } from 'luxon'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_MERCHANT_DASHBOARD = 'getMerchantDashboard'
export const SET_MERCHANT_DASHBOARD = 'getMerchantDashboard'
export const GET_MERCHANT_ANALYTICS_DASHBOARD = 'getMerchantAnalyticsDashboard'
export const SET_MERCHANT_ANALYTICS_DASHBOARD = 'setMerchantAnalyticsDashboard'

export const GET_DASHBOARD_GENERATE_REPORT_DAY = 'getDashboardGenerateReportDay'
export const SET_DASHBOARD_GENERATE_REPORT_DAY = 'setDashboardGenerateReportDay'

export const GET_DASHBOARD_GENERATE_REPORT_WEEK = 'getDashboardGenerateReportWeek'
export const SET_DASHBOARD_GENERATE_REPORT_WEEK = 'setDashboardGenerateReportWeek'

export const GET_DASHBOARD_GENERATE_REPORT_MONTH = 'getDashboardGenerateReportMonth'
export const SET_DASHBOARD_GENERATE_REPORT_MONTH = 'setDashboardGenerateReportMonth'

export const GET_DASHBOARD_GENERATE_REPORT_YEAR = 'getDashboardGenerateReportYear'
export const SET_DASHBOARD_GENERATE_REPORT_YEAR = 'setDashboardGenerateReportYear'

export const GET_ADMIN_DASHBOARD = 'getAdminDashboard'
export const SET_ADMIN_DASHBOARD = 'setAdminDashboard'

export const GET_MERCHANT_DAY_PAYOUT_SUMMARY = 'getMerchantDayPayoutSummary'
export const SET_MERCHANT_DAY_PAYOUT_SUMMARY = 'setMerchantDayPayoutSummary'

export default {
  namespaced: true,
  state: {
    merchantDashboard: {},
    merchantAnalyticsDashboard: {},
    merchantDashboardDay: {},
    merchantDashboardWeek: {},
    merchantDashboardMonth: {},
    merchantDashboardYear: {},
    adminDashboard: {},
    merchantDayPayoutSummary: {},
  },
  getters: {
    dashboardData: state => {
      const currentState = state.merchantDashboard

      if (currentState) {
        return currentState
      }
      return []
    },
    dashboardAnalyticsData: state => {
      const currentState = state.merchantAnalyticsDashboard

      if (currentState) {
        return currentState
      }
      return []
    },
    activeChartData: state => type => {
      let currentState
      if (type === 'day') {
        currentState = state.merchantDashboardDay
      }
      if (type === 'month') {
        currentState = state.merchantDashboardMonth
      }
      if (type === 'year') {
        currentState = state.merchantDashboardYear
      }
      if (type === 'week') {
        currentState = state.merchantDashboardWeek
      }

      if (currentState) {
        return currentState
      }
      return []
    },
  },
  mutations: {
    [SET_DASHBOARD_GENERATE_REPORT_DAY](state, val) {
      state.merchantDashboardDay = val
    },
    [SET_DASHBOARD_GENERATE_REPORT_MONTH](state, val) {
      state.merchantDashboardMonth = val
    },
    [SET_DASHBOARD_GENERATE_REPORT_YEAR](state, val) {
      state.merchantDashboardYear = val
    },
    [SET_DASHBOARD_GENERATE_REPORT_WEEK](state, val) {
      state.merchantDashboardWeek = val
    },
    [SET_MERCHANT_DASHBOARD](state, val) {
      state.merchantDashboard = val
    },
    [SET_MERCHANT_ANALYTICS_DASHBOARD](state, val) {
      state.merchantAnalyticsDashboard = val
    },
    [SET_ADMIN_DASHBOARD](state, val) {
      state.adminDashboard = val
    },
    [SET_MERCHANT_DAY_PAYOUT_SUMMARY](state, val) {
      state.merchantDayPayoutSummary = val
    },
  },
  actions: {
    [GET_MERCHANT_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/merchant-dashboard`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_ANALYTICS_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/merchant/statistic-dashboard`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_ANALYTICS_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_DAY](context, payload) {
      if (!payload.date) {
        payload.date = DateTime.now().toFormat('dd-MM-yyyy')
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/dashboard-generate-report/day-${payload.date}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_DAY, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_MONTH](context, payload) {
      if (!payload.year) {
        payload.year = DateTime.now().year
      }
      if (!payload.month) {
        payload.month = DateTime.now().month
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/dashboard-generate-report/month-${payload.month}?skip=${payload.skip}&limit=${payload.limit}&year=${payload.year}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_MONTH, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_WEEK](context, payload) {
      if (!payload.date) {
        payload.date = DateTime.now().toFormat('dd-MM-yyyy')
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/dashboard-generate-report/last-week-${payload.date}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_WEEK, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_DASHBOARD_GENERATE_REPORT_YEAR](context, payload) {
      if (!payload.year) {
        payload.year = DateTime.now().year
      }
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/dashboard-generate-report/${payload.year}?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_DASHBOARD_GENERATE_REPORT_YEAR, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_ADMIN_DASHBOARD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}dashboard/admin-dashboard`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_ADMIN_DASHBOARD, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [GET_MERCHANT_DAY_PAYOUT_SUMMARY](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}crm-reporting/merchant/payout-status`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_DAY_PAYOUT_SUMMARY, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

  },
}
