import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_CUSTOMER_BILLS = 'getCustomerBills'
export const GET_CUSTOMER_BILLS_BY_ID = 'getCustomerBillsById'
export const CREATE_CUSTOMER_BILL_BILL_METHOD = 'createCustomerBillBillMethod'
export const CREATE_CUSTOMER_BILL_PAYMENT_METHOD = 'createCustomerBillPaymentMethod'
export const UPDATE_CUSTOMER_BILLS_BY_ID = 'updateCustomerBillsById'
export const GET_FPX_FAILED_REASON = 'getFpxFailedReason'

export const SET_CUSTOMER_BILLS = 'setCustomerBillsList'
export const SET_CUSTOMER_BILL = 'setCustomerBill'

export default {
  namespaced: true,
  state: {
    customerBillsList: null,
    customerBill: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.customerBillsList
      if (list) {
        return list
      }
      return []
    },

    specificCustomerBillsList: state => {
      const currentState = state.specificCustomerBillsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    activeCustomerBillsList: state => {
      const currentState = state.customerBillsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    customerBillsList: state => {
      const currentState = state.customerBillsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    getCustomerBill: state => {
      const currentState = state.customerBill
      if (currentState) {
        return currentState
      }
      return {}
    },
  },
  mutations: {
    [SET_CUSTOMER_BILLS](state, val) {
      state.customerBillsList = val
    },
    [SET_CUSTOMER_BILL](state, val) {
      state.customerBill = val
    },
  },
  actions: {
    [GET_CUSTOMER_BILLS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMER_BILLS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_CUSTOMER_BILL_BILL_METHOD](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/customer-bill-method`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_CUSTOMER_BILL_PAYMENT_METHOD](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/switch/create-payment-bill-direct`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_CUSTOMER_BILLS_BY_ID](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/${payload}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMER_BILL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_CUSTOMER_BILLS_BY_ID](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/${payload.customerBillId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_FPX_FAILED_REASON](context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/fpx-failed-reason/${payload.transaction_invoice_no}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
