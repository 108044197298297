import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_BANKS = 'getBanks'
export const CREATE_BANK = 'createBank'
export const UPDATE_BANK = 'updateBank'

export const SET_BANKS_LIST = 'testMutation'

export default {
  namespaced: true,
  state: {
    bankList: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.bankList
      if (list) {
        return list
      }
      return []
    },
    activeBankList: state => {
      const currentState = state.bankList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    bankList: state => {
      const currentState = state.bankList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_BANKS_LIST](state, val) {
      state.bankList = val
    },
  },
  actions: {
    [GET_BANKS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}banks`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.token)
              context.commit(SET_BANKS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
  [CREATE_BANK](context, payload) {
    return new Promise((resolve, reject) => {
      axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}banks`, payload)
        .then(res => {
          if (res.data.response_code === 2000) {
            useJwt.setToken(res.data.token)
            SystemHelper.successHandler(res)
            resolve(res)
          } else {
            SystemHelper.errorHandler(res)
            resolve(res)
          }
        })
        .catch(err => {
          SystemHelper.handleCatch(err)
          reject(err)
        })
    })
  },
  [UPDATE_BANK](context, payload) {
    const { bankId } = payload
    console.log('payload', payload)

    return new Promise((resolve, reject) => {
      axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}banks/${bankId}`, payload)
        .then(res => {
          if (res.data.response_code === 2000) {
            useJwt.setToken(res.data.token)
            SystemHelper.successHandler(res)
            resolve(res)
          } else {
            alert(res.data.response_code)
            SystemHelper.errorHandler(res)
            resolve(res)
          }
        })
        .catch(err => {
          SystemHelper.handleCatch(err)
          reject(err)
        })
    })
  },
}
