import Vue from 'vue'
import VueRouter from 'vue-router'
import merchantRoute from '@/router/merchantRoute'
import publicRoute from '@/router/publicRoute'
import store from '@/store'

const routeList = []
const routes = routeList.concat(merchantRoute, publicRoute)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

const DEFAULT_TITLE = 'lean.x '

// router.beforeEach((to, from, next) => {
//   document.title = process.env.VUE_APP_TITLE || DEFAULT_TITLE
//   if (to.name === 'login' && store.getters['auth/getLoginStatus']) next({ name: 'dashboard' })
//
//   console.log(to.meta.requireAuth)
//   if (to.meta.requireAuth) {
//
//   }
//
//   next()
// })

router.beforeResolve((to, from, next) => {
  document.title = process.env.VUE_APP_TITLE || DEFAULT_TITLE

  if (to.name === 'login' && store.getters['auth/getLoginStatus']) next({ name: 'dashboard' })

  if (to.meta.requireAuth && !store.getters['auth/getLoginStatus']) {
    store.dispatch('auth/updateLoginPageErrorMessage', 'You need to login to access the page').then(() => next({ path: '/' }))
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
