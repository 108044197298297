import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const UPLOAD_CUSTOMER_BILLS_EXCEL = 'uploadCustomerBillsExcel'

export default {
  namespaced: true,
  state: {
    paymentSettingsList: null,
    paymentSetting: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.paymentSettingsList
      if (list) {
        return list
      }
      return []
    },
  },
  mutations: {
  },
  actions: {
    [UPLOAD_CUSTOMER_BILLS_EXCEL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-upload-files/upload-customer-bills-excel`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
