import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// import systemConstants from '@/store/administration/systemConstants'
// import administration from './administration'
// import auth from './auth'
// import users from './administration/users'
// import accounts from '@/store/administration/accounts'
// import banks from '@/store/administration/banks'
// import collections from '@/store/administration/collections'
// import catalogs from '@/store/administration/catalogs'
// import businessOwnerDetails from '@/store/administration/businessOwnerDetails'
// import merchantAPI from '@/store/administration/merchantAPI'
// import customers from '@/store/administration/customers'
// import discounts from '@/store/administration/discounts'
// import domains from '@/store/administration/domains'
// import pages from '@/store/administration/pages'
// import products from '@/store/administration/products'
// import recurring from '@/store/administration/recurring'
// import customerBills from '@/store/administration/customerBills'
// import dashboard from '@/store/merchant/dashboard'

import breadcrumbs from '@/store/app/breadcrumbs'

import systemConstants from '@/store/merchant/systemConstants'
import merchant from './merchant'
import auth from './auth'
import users from './merchant/users'
import accounts from '@/store/merchant/accounts'
import banks from '@/store/merchant/banks'
import collections from '@/store/merchant/collections'
import businessOwnerDetails from '@/store/merchant/businessOwnerDetails'
import merchantAPI from '@/store/merchant/merchantAPI'
import customers from '@/store/merchant/customers'
import discounts from '@/store/merchant/discounts'
import products from '@/store/merchant/products'
import productCategories from '@/store/merchant/productCategories'
import productSubCategories from '@/store/merchant/productSubCategories'
import productTags from '@/store/merchant/productTags'
import customerBills from '@/store/merchant/customerBills'
import dashboard from '@/store/merchant/dashboard'
import paymentRecords from '@/store/merchant/paymentRecords'
import selfSettlement from '@/store/merchant/selfSettlement'
import reportDownloads from '@/store/merchant/reportDownloads'
import subscriptions from '@/store/merchant/subscriptions'
import subscriptionHistory from '@/store/merchant/subcriptionHistories'

import paymentpage from '@/store/paymentpage/index'
import communication from '@/store/merchant/communication'
import masterPool from '@/store/merchant/masterPool'
import invoicepage from '@/store/invoicepage/index'
import payout from '@/store/merchant/payout'

import paymentUploadFile from '@/store/merchant/paymentUploadFile'
import tour from '@/store/merchant/tour'

import successField from '@/store/merchant/successField'
import merchantStore from '@/store/merchant/stores'

import supportDetails from '@/store/merchant/supportDetails'
import companyDetails from '@/store/merchant/companyDetails'
import shareSettings from '@/store/merchant/shareSettings'
import paymentSettings from '@/store/merchant/paymentSettings'

import mediaStore from '@/store/merchant/media'
import shippingProfiles from '@/store/merchant/shippingProfiles'
import shippingRates from '@/store/merchant/shippingRates'
import shippingZones from '@/store/merchant/shippingZones'
import development from '@/store/merchant/development'

import publicStore from '@/store/store/publicStore'
import virtualAccount from '@/store/merchant/virtualAccount'

import storefront from '@/store/storefront'
import storeFront from '@/store/store/storefront'

Vue.use(Vuex)

// Vuex Persist
const vueLocalStorage = new VuexPersist({
  key: 'STORAGE_KEY',
  storage: window.localStorage,
  reducer: state => ({
    accounts: {
      accountMe: state.accounts.accountMe,
    },
    systemConstants: {
      systemConstantsList: state.systemConstants.systemConstantsList,
    },
    merchant: {
      merchantVirtualAccountList: state.merchant.merchantVirtualAccountList,
    },
    auth: {
      isLoggedIn: state.auth.isLoggedIn,
    },
    storefront: {
      cart: state.storefront.cart,
    },
    tour: {
      tourSettings: state.tour.tourSettings,
    },
  }),
})

export default new Vuex.Store({
  plugins: [vueLocalStorage.plugin],
  modules: {
    breadcrumbs,
    dashboard,
    app,
    appConfig,
    verticalMenu,
    systemConstants,
    auth,

    discounts,

    payout,
    communication,
    tour,

    users,
    accounts,
    selfSettlement,
    collections,
    merchantAPI,
    customerBills,
    shippingProfiles,
    banks,
    customers,
    products,
    productCategories,
    productTags,
    productSubCategories,
    companyDetails,
    businessOwnerDetails,
    paymentRecords,
    merchant,
    paymentpage,
    invoicepage,
    masterPool,
    subscriptionHistory,
    subscriptions,
    reportDownloads,
    paymentUploadFile,

    successField,
    supportDetails,
    shareSettings,
    paymentSettings,

    merchantStore,
    shippingRates,
    shippingZones,
    virtualAccount,

    mediaStore,

    storefront,
    storeFront,

    // dev only
    development,

    publicStore,
  },
  strict: process.env.DEV,
})
