import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_SUBSCRIPTION_HISTORIES = 'getSubscriptionHistories'
export const GET_SUBSCRIPTION_HISTORY_BY_ID = 'getSubscriptionHistoryById'
export const GET_MERCHANT_SUBSCRIPTION_HISTORIES_LIST = 'getMerchantSubscriptionHistoriesList'
export const CREATE_SUBSCRIPTION_HISTORIES = 'createSubscriptionHistories'
export const UPDATE_SUBSCRIPTION_HISTORIES = 'updateSubscriptionHistories'
export const DELETE_SUBSCRIPTION_HISTORIES = 'deleteSubscriptionHistories'

export const SET_SUBSCRIPTION_HISTORIES_LIST = 'setSubscriptionHistoriesList'
export const SET_MERCHANT_SUBSCRIPTION_HISTORIES_LIST = 'setMerchantSubscriptionHistoriesList'
export const SET_SUBSCRIPTION_HISTORY = 'setSubscriptionHistory'

export default {
  namespaced: true,
  state: {
    subscriptionHistoriesList: null,
    merchantSubscriptionHistoriesList: null,
    subscriptionHistory: null,
  },
  getters: {
    getPaginationInfoByStateKey: state => stateKey => {
      console.log('test')
      console.log(state[stateKey])
      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.subscriptionHistoriesList
      if (list) {
        return list
      }
      return []
    },
    activeSubscriptionHistoriesList: state => {
      const currentState = state.subscriptionHistoriesList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },

    getSelectedSubscriptionHistory: state => {
      const currentState = state.subscriptionHistory

      if (currentState) {
        const list = currentState.list.data
        if (list) {
          return list
        }
        return {}
      }
      return {}
    },
    subscriptionHistoriesList: state => {
      const currentState = state.subscriptionHistoriesList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    merchantSubscriptionHistoriesList: state => {
      const currentState = state.merchantSubscriptionHistoriesList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_SUBSCRIPTION_HISTORIES_LIST](state, val) {
      state.subscriptionHistoriesList = val
    },
    [SET_MERCHANT_SUBSCRIPTION_HISTORIES_LIST](state, val) {
      state.merchantSubscriptionHistoriesList = val
    },
    [SET_SUBSCRIPTION_HISTORY](state, val) {
      state.subscriptionHistory = val
    },
  },
  actions: {
    [GET_SUBSCRIPTION_HISTORIES](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTION_HISTORIES_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SUBSCRIPTION_HISTORIES_LIST](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/merchant-subscription-history`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_SUBSCRIPTION_HISTORIES_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_SUBSCRIPTION_HISTORY_BY_ID](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_SUBSCRIPTION_HISTORY, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SUBSCRIPTION_HISTORIES](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_SUBSCRIPTION_HISTORIES](context, payload) {
      const { payloadId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/${payloadId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_SUBSCRIPTION_HISTORIES](context, payloadId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}subscription-histories/${payloadId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
