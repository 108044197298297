import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://dev-api.leanpay.my/api/v1',
  // timeout: 1000,
  // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  // headers: { 'content-type': 'multipart/form-data' },
})

Vue.prototype.$http = axiosIns

export default axiosIns
