import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_MERCHANT_SHIPMENTS = 'getMerchantShipments'
export const GET_MERCHANT_STORES = 'getMerchantStores'
export const GET_MERCHANT_STORE_SETTINGS = 'getMerchantStoreSettings'
export const GET_BUSINESS_HOURS_BY_STORE_ID = 'getBusinessHoursByStoreId'
export const CREATE_MERCHANT_DEFAULT_STORE = 'createMerchantDefaultStore'
export const CREATE_MERCHANT_STORE_SETTINGS = 'createMerchantStoreSettings'
export const UPDATE_MERCHANT_STORE_SETTINGS = 'updateMerchantStoreSettings'
export const CHECKOUT_SHIPPING_PAYMENT = 'checkoutShippingPayment'
export const CREATE_MERCHANT_BUSINESS_HOURS = 'createMerchantBusinessHours'
export const UPDATE_MERCHANT_BUSINESS_HOURS = 'updateMerchantBusinessHours'
export const UPDATE_MERCHANT_STORE = 'updateMerchantStore'
export const UPDATE_MERCHANT_SHIPMENT = 'updateMerchantShipment'
export const CHECKOUT_SHIPPING_PAYMENT_ID = 'checkoutShippingPaymentById'
export const DELIVERY_PRICE_CHECK = 'deliveryPriceCheck'
export const SET_MERCHANT_STORES_LIST = 'setMerchantStoresList'
export const SET_MERCHANT_STORE_SETTINGS_LIST = 'setMerchantStoreSettingsList'
export const SET_MERCHANT_SHIPMENTS_LIST = 'setMerchantShipmentsList'
export const DOWNLOAD_CONSIGNMENT_NOTE = 'downloadConsignmentNote'
export const SET_COURIER_PROVIDER_LIST = 'setCourierProviderList'
export const SET_BUSINESS_HOURS_BY_STORE_ID = 'setBusinessHoursByStoreId'

export default {
  namespaced: true,
  state: {
    merchantStoresList: null,
    courierProviderList: null,
    merchantStoreSettingsList: null,
    merchantShipmentsList: null,
    merchantStoreSettingsById: null,
  },
  getters: {
    getRawData: state => key => {
      const data = state[key]
      if (data) {
        return data.list
      } return {}
    },
    getPaginationInfoByStateKey: state => stateKey => {
      console.log('here', state[stateKey])
      console.log('here', state)
      console.log('here', stateKey)

      const list = state[stateKey]
      if (list) {
        return list.list
      }
      return []
    },

    getMerchantStoreSettings: state => {
      const list = state.merchantStoreSettingsList
      if (list) {
        const filtered = list.list.data.filter(el => el.is_active)
        return filtered[0]
      }
      return { }
    },
    getMerchantStores: state => {
      const allStores = state.merchantStoresList
      if (allStores) {
        return allStores.list.data
      }
      return []
    },
    getMerchantShipments: state => {
      const allStores = state.merchantShipmentsList
      if (allStores) {
        return allStores.list.data
      }
      return []
    },
    getMerchantCourierByMethod: state => key => {
      const allStores = state.courierProviderList
      if (allStores) {
        const filtered = allStores.filter(el => el.send_methods === key)

        if (key === 'all') {
          return allStores
        }
        return filtered
      }
      return []
    },
    getPaginationInfo: state => {
      const list = state.storesList
      if (list) {
        return list
      }
      return []
    },
  },
  mutations: {
    [SET_MERCHANT_STORES_LIST](state, val) {
      state.merchantStoresList = val
    },
    [SET_MERCHANT_SHIPMENTS_LIST](state, val) {
      state.merchantShipmentsList = val
    },
    [SET_COURIER_PROVIDER_LIST](state, val) {
      state.courierProviderList = val
    },
    [SET_MERCHANT_STORE_SETTINGS_LIST](state, val) {
      state.merchantStoreSettingsList = val
    },
    [SET_BUSINESS_HOURS_BY_STORE_ID](state, val) {
      state.merchantStoreSettingsById = val
    },
  },
  actions: {
    [GET_MERCHANT_STORES](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/merchant-stores?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_STORES_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_STORE_SETTINGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}store-settings/merchant-store-settings`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_STORE_SETTINGS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_BUSINESS_HOURS_BY_STORE_ID](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/opening-days/store-based/{_id}
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}opening-days/store-based/${payload.store_id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_BUSINESS_HOURS_BY_STORE_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else if (res.data.response_code === 2111) {
              SystemHelper.errorHandler(res, true)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_MERCHANT_BUSINESS_HOURS](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/stores/edit/opening-day-hours
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/create/opening-day-hours`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_BUSINESS_HOURS](context, payload) {
      return new Promise((resolve, reject) => {
        // /api/v1/stores/edit/opening-day-hours
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/edit/opening-day-hours`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_MERCHANT_SHIPMENTS](context, payload) {
      return new Promise((resolve, reject) => {
        // axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipments/merchant-shipments?skip=${payload.skip}&limit=${payload.limit}`, payload)

        /// api/v1/shipments/order/merchant-shipments
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipments/order/merchant-shipments?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_MERCHANT_SHIPMENTS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [CHECKOUT_SHIPPING_PAYMENT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}parcel-asia/merchant/check-out-shipping-payment/${payload.shipmentId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_SHIPMENT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}shipments/${payload.shipmentId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_MERCHANT_DEFAULT_STORE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/create/default-store-collection`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_MERCHANT_STORE_SETTINGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}store-settings`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_STORE_SETTINGS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}store-settings/${payload.id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // https://stag-api.leanpay.my/api/v1/parcel-asia/consignment-note
    [DOWNLOAD_CONSIGNMENT_NOTE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}parcel-asia/consignment-note`, payload, { responseType: 'blob' })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_MERCHANT_STORE](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}stores/${payload.storeId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // /api/v1/parcel-asia/merchant/check-out-shipping-payment/{_id}{payload.storeId}
    [CHECKOUT_SHIPPING_PAYMENT_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}parcel-asia/merchant/check-out-shipping-payment/${payload.shipmentId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // /api/v1/parcel-asia/merchant/auth-delivery-price-check
    [DELIVERY_PRICE_CHECK](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}parcel-asia/merchant/auth-delivery-price-check`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              context.commit(SET_COURIER_PROVIDER_LIST, res.data.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
