import _ from 'lodash'
import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_DOWNLOADABLE_LIST = 'getDownloadableList'
export const SET_DOWNLOADABLE_LIST = 'setDownloadableList'
export const DOWNLOAD_CUSTOMER_BILLS = 'downloadCustomerBills'
export const DOWNLOAD_PAYOUT_LIST = 'downloadPayoutList'
export const DOWNLOAD_SELF_SETTLEMENT_LIST = 'downloadSelfSettlementList'
export const DOWNLOAD_MASTER_POOL_LIST = 'downloadMasterPoolList'

export default {
  namespaced: true,
  state: {
    downloadableList: null,
  },
  getters: {
    getDownloadableList: state => {
      const currentState = state.downloadableList
      if (currentState) {
        const list = currentState.data
        return list
      }
      return []
    },
    getDownloadableListMeta: state => {
      const currentState = state.downloadableList

      if (currentState) {
        const duplicateQ = { ...currentState }
        delete duplicateQ.data
        return duplicateQ
      }
      return []
    },
  },
  mutations: {
    [SET_DOWNLOADABLE_LIST](state, val) {
      state.downloadableList = val
    },
  },
  actions: {
    [GET_DOWNLOADABLE_LIST](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.TOOLS_URL}api/v1/env/mobile/superadmin/ReportGenerationList`, payload)
        // axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}user-tour-setting/merchant-user-tour-setting`, payload)
          .then(res => {
            if (res.data.response_code === 2100) {
              console.log(res.data)
              context.commit(SET_DOWNLOADABLE_LIST, res.data.data.list)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DOWNLOAD_CUSTOMER_BILLS](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        // axiosIns.post('https://report.leanpay.my/api/v1/env/mobile/superadmin/ReportGenerationList', payload)
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}background-task-report/generate-report-collection?skip=${payload.skip}&limit=${payload.limit}&collection_id=${payload.collection_id}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DOWNLOAD_PAYOUT_LIST](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        // http://127.0.0.1:5049/api/v1/background-task-report/generate-report-payout?skip=0&limit=100
        // downloadPayout
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}background-task-report/generate-report-payout?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    [DOWNLOAD_SELF_SETTLEMENT_LIST](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        // background-task-report/generate-report-self-settlement?skip=0&limit=10000
        // downloadPayout
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}background-task-report/generate-report-self-settlement?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DOWNLOAD_MASTER_POOL_LIST](context, payload) {
      // return context.state.tourSettings
      // /api/v1/user-tour-setting/merchant-user-tour-setting
      return new Promise((resolve, reject) => {
        // /api/v1/background-task-report/generate-report-balance-pool
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}background-task-report/generate-report-balance-pool?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
