import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_CUSTOMERS = 'getCustomers'
export const GET_CUSTOMER_BY_ID = 'getCustomerById'
export const SET_CUSTOMERS = 'setCustomersList'
export const SET_CUSTOMER_BY_ID = 'setCustomerById'

export const CREATE_CUSTOMER = 'createCustomer'
export const UPDATE_CUSTOMER = 'updateCustomer'

export default {
  namespaced: true,
  state: {
    customersList: null,
    customer: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.customersList
      if (list) {
        return list.list
      }
      return []
    },
    activeCustomersList: state => {
      const currentState = state.customersList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    merchantCustomersList: state => {
      const currentState = state.customersList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_CUSTOMERS](state, val) {
      state.customersList = val
    },
    [SET_CUSTOMER_BY_ID](state, val) {
      state.customer = val
    },
  },
  actions: {
    [GET_CUSTOMERS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customers?skip=${payload.skip}&limit=${payload.limit}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMERS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_CUSTOMER_BY_ID](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customers/${payload}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_CUSTOMER_BY_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_CUSTOMER](context, payload) {
      const customerId = payload.id
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customers/${customerId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_CUSTOMER](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customers`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },

}
