import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import Router from '@/router'

export const GET_ACCESS_TOKEN = 'getAccessToken'
export const SET_ACCESS_TOKEN = 'setAccessToken'
export const SET_LOGIN_PAGE_ERROR_MESSAGE = 'setLoginPageErrorMessage'
export const UPDATE_LOGIN_PAGE_ERROR_MESSAGE = 'updateLoginPageErrorMessage'

export const SET_IS_LOGGED_IN = 'setIsLoggedIn'
export const TEST_TOKEN_VALIDITY = 'testTokenValidity'

export const LOGOUT = 'logout'
export const FORGOT_PASSWORD = 'forgotPassword'
export const CHANGE_PASSWORD = 'changePassword'

export const GET_MERCHANT_ACCESS_TOKEN = 'getMerchantAccessToken'
export const SET_MERCHANT_ACCESS_TOKEN = 'setMerchantAccessToken'

export const PURGE_AUTH = 'purgeAuth'

export const TOGGLE_DEBUG = 'toggleDebug'
export const SET_DEBUG_TOGGLE = 'setDebugToggle'

export default {
  namespaced: true,
  state: {
    debugToggle: false,
    isLoggedIn: false,
    accessToken: null,
    merchantAccessToken: null,
    loginPageErrorMessage: null,
  },
  getters: {
    getDebugToggle: state => state.debugToggle,
    getLoginStatus: state => state.isLoggedIn,
    getLoginPageErrorMessage: state => state.loginPageErrorMessage,
  },
  mutations: {
    [SET_IS_LOGGED_IN](state, val) {
      state.isLoggedIn = val
    },
    [SET_ACCESS_TOKEN](state, val) {
      state.accessToken = val
    },
    [SET_LOGIN_PAGE_ERROR_MESSAGE](state, val) {
      state.loginPageErrorMessage = val
    },
    [SET_DEBUG_TOGGLE](state, val) {
      state.debugToggle = val
    },
    [SET_MERCHANT_ACCESS_TOKEN](state, val) {
      state.merchantAccessToken = val
    },
    [PURGE_AUTH](state) {
      state.accessToken = null
      state.merchantAccessToken = null
      state.isLoggedIn = false
      window.localStorage.clear()
      // console.log('purgeAuth')
      useJwt.destroyToken()

      if (Router.currentRoute.path !== '/login') {
        Router.replace('/login').catch(() => true)
      }
    },
  },
  actions: {
    [TOGGLE_DEBUG](context, payload) {
      context.commit(SET_DEBUG_TOGGLE, payload)
    },
    [UPDATE_LOGIN_PAGE_ERROR_MESSAGE](context, payload) {
      context.commit(SET_LOGIN_PAGE_ERROR_MESSAGE, payload)
    },
    [LOGOUT](context) {
      context.commit('accounts/setAccountMe', null, { root: true })
      context.commit(PURGE_AUTH)
    },
    [GET_MERCHANT_ACCESS_TOKEN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}auth/merchant-access-token`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.data.access_token)
              context.commit(SET_MERCHANT_ACCESS_TOKEN, res.data.data.access_token)
              context.commit(SET_IS_LOGGED_IN, true)
              resolve(res)
            } else {
              context.commit(SET_LOGIN_PAGE_ERROR_MESSAGE, res.data.response_code)
              // alert(res.data.response_code)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    [FORGOT_PASSWORD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}portal/reset-password`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              resolve(res)
            } else {
              // alert(res.data.response_code)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    [CHANGE_PASSWORD](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}portal/forgot-password`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              resolve(res)
            } else {
              // alert(res.data.response_code)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    [GET_ACCESS_TOKEN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}auth/access-token-viu`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.data.access_token)
              context.commit(SET_ACCESS_TOKEN, res.data.data.access_token)
              context.commit(SET_IS_LOGGED_IN, true)
              resolve(res)
            } else {
              context.commit(SET_LOGIN_PAGE_ERROR_MESSAGE, res.data.response_code)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    [TEST_TOKEN_VALIDITY](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}auth/test-token`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              useJwt.setToken(res.data.data.access_token)
              context.commit(SET_ACCESS_TOKEN, res.data.data.access_token)
              context.commit(SET_IS_LOGGED_IN, true)

              resolve(res)
            } else {
              context.commit(SET_LOGIN_PAGE_ERROR_MESSAGE, res.data.response_code)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
  },
}
