import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const GET_COMPANY_DETAILS = 'getCompanyDetails'
export const GET_COMPANY_DETAIL_ME = 'getCompanyDetailMe'
export const GET_COMPANY_DETAIL_BY_ID = 'getCompanyDetailById'
export const CREATE_COMPANY_DETAILS = 'createCompanyDetails'
export const UPDATE_COMPANY_DETAILS = 'updateCompanyDetails'
export const UPDATE_COMPANY_DETAILS_INFO_ME = 'updateCompanyDetailsInfoMe'
export const DELETE_COMPANY_DETAILS = 'deleteCompanyDetails'

export const SET_COMPANY_DETAILS_LIST = 'setCompanyDetailsList'
export const SET_COMPANY_DETAIL = 'setCompanyDetail'
export const SET_COMPANY_DETAIL_ME = 'setCompanyDetailMe'

export default {
  namespaced: true,
  state: {
    companyDetailsList: null,
    companyDetail: null,
    companyDetailMe: null,
  },
  getters: {
    getPaginationInfo: state => {
      const list = state.companyDetailsList
      if (list) {
        return list
      }
      return []
    },
    activeBankList: state => {
      const currentState = state.bankList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    getCompanyDetailMe: state => {
      const currentState = state.companyDetailMe

      if (currentState) {
        return currentState
      }
      return []
    },
    companyDetailsList: state => {
      const currentState = state.companyDetailsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_COMPANY_DETAILS_LIST](state, val) {
      state.companyDetailsList = val
    },
    [SET_COMPANY_DETAIL](state, val) {
      state.companyDetail = val
    },
    [SET_COMPANY_DETAIL_ME](state, val) {
      state.companyDetailMe = val
    },
  },
  actions: {
    [GET_COMPANY_DETAILS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}company-details`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMPANY_DETAILS_LIST, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COMPANY_DETAIL_BY_ID](context, companyId) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}company-details/${companyId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMPANY_DETAIL, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_COMPANY_DETAIL_ME](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}company-details/options/company-me`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              context.commit(SET_COMPANY_DETAIL_ME, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_COMPANY_DETAILS](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}company-details`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_COMPANY_DETAILS](context, payload) {
      const { companyId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}company-details/${companyId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              // console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [UPDATE_COMPANY_DETAILS_INFO_ME](context, payload) {
      const { companyId } = payload
      return new Promise((resolve, reject) => {
        axiosIns.put(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}company-details/info/${companyId}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [DELETE_COMPANY_DETAILS](context, companyId) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}company-details/${companyId}`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              useJwt.setToken(res.data.token)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
