import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import SystemHelper from '@/common/systemHelper'

export const GET_SYSTEM_CONSTANTS = 'getSystemConstants'
export const SET_SYSTEM_CONSTANTS = 'setSystemConstantsList'

export default {
  namespaced: true,
  state: {
    systemConstantsList: null,
  },
  getters: {
    getSystemConstantsByKey: state => key => {
      const obj = state.systemConstantsList[key]
      if (obj) {
        return obj
      }
      return []
    },
    activeSystemConstantsList: state => {
      const currentState = state.systemConstantsList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.is_active)
        }
        return []
      }
      return []
    },
    systemConstantsList: state => {
      const currentState = state.systemConstantsList

      if (currentState) {
        const list = currentState.list.data
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
  },
  mutations: {
    [SET_SYSTEM_CONSTANTS](state, val) {
      state.systemConstantsList = val
    },
  },
  actions: {
    [GET_SYSTEM_CONSTANTS](context) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}onboard/constant/system-constant`)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data)
              context.commit(SET_SYSTEM_CONSTANTS, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
