export default [
  {
    path: '/payment',
    name: 'public-payment-page',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/paymentpage/payv2/payment.vue'),
    children: [
      {
        path: 'bills/:uuid',
        component: () => import('@/views/paymentpage/payv2/payment.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'collections/:uuid',
        component: () => import('@/views/paymentpage/payv2/payment.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'invoice/:uuid',
        component: () => import('@/views/paymentpage/payv2/payment.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'thankyou',
        name: 'public-thank-you',
        component: () => import('@/views/paymentpage/payv2/thankyou.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'errors',
        name: 'public-error',
        component: () => import('@/views/paymentpage/payv2/errors.vue'),
        meta: {
          layout: 'full',
        },
      },
    ],
  },
  {
    path: '/store',
    name: 'public-shop-page',
    meta: {
      layout: 'full',
    },
    redirect: '/store/:id',
    component: () => import('@/views/publicRoute.vue'),
    children: [
      {
        path: ':id',
        component: () => import('@/views/store/store.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: ':id/cart',
        name: 'public-cart-page',
        component: () => import('@/views/store/cartPage.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: ':id/checkout',
        name: 'public-checkout-page',
        component: () => import('@/views/store/checkout.vue'),
        meta: {
          layout: 'full',
        },
      },
    ],
  },
  {
    path: '/store-not-found',
    name: 'public-404-page',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/store/public404.vue'),
  },
  // {
  //   path: '/cart',
  //   name: 'public-cart-page',
  //   meta: {
  //     layout: 'full',
  //   },
  //   component: () => import('@/views/store/cartPage.vue'),
  // },
  // {
  //   path: '/checkout',
  //   name: 'public-checkout-page',
  //   meta: {
  //     layout: 'full',
  //   },
  //   component: () => import('@/views/store/checkout.vue'),
  // },

  {
    path: '/account-switcher',
    name: 'account-switcher',
    component: () => import('@/views/AccountSwitcher.vue'),
    meta: {},
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/fpx-status',
    name: 'fpx-health-monitor',
    component: () => import('@/views/fpxHealthMonitor.vue'),
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/forgot-password',
    name: 'forgot-password-public',
    component: () => import('@/views/forgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-username',
    name: 'forgot-username-public',
    component: () => import('@/views/forgotUsername.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pay/:type/not-found',
    name: 'error-page-public',
    component: () => import('@/views/paymentpage/PublicErrorPage.vue'),
    meta: {
      layout: 'full',
    },
  },

  // alt-payment version
  {
    path: '/alt-payment/collections/:uuid',
    name: 'alt-payment-page',
    component: () => import('@/views/paymentpage/pay.vue'),
    meta: {
      layout: 'full',
    },
  },
  // modern-payment version
  {
    path: '/modern-payment/generic-topup',
    alias: ['/modern-payment/pool-topup', '/modern-payment/prefund-topup', '/modern-payment/subscription-payment'],
    name: 'modern-generic-payment-page',
    component: () => import('@/views/paymentpage/genericPaymentComponent.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/modern-payment/bill/:uuid',
    name: 'modern-bill-payment',
    component: () => import('@/views/paymentpage/bill.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pay/collections/:uuid',
    name: 'payment-page',
    alias: ['/modern-payment/collections/:uuid'],
    component: () => import('@/views/paymentpage/modern-payment/pay.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/modern-payment/invoice/:invoiceId',
    name: 'modern-customer-bills-page',
    component: () => import('@/views/invoicepage/pay.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/modern-payment/thankyou',
    name: 'modern-payment-thank-you-page',
    component: () => import('@/views/paymentpage/thankyou.vue'),
    meta: {
      layout: 'full',
    },
  },

  // mobile store
  {
    path: '/storefront/mobile/:storeID',
    name: 'storefront-mobile',
    component: () => import('@/views/storefront/mobile/Products.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  // 	path: "/storefront/web/:storeID",
  // 	name: "storefront-web",
  // 	component: () => import("@/views/storefront/web/ShopPage.vue"),
  // 	meta: {
  // 		layout: "full",
  // 	},
  // },
  // {
  // 	path: "/storefront/web/:storeID/checkout",
  // 	name: "storefront-web-checkout",
  // 	component: () => import("@/views/storefront/web/CheckoutPage.vue"),
  // 	meta: {
  // 		layout: "full",
  // 	},
  // },
  {
    path: '/storefront/mobile/:storeID/product/:id',
    name: 'storefront-mobile-product-info',
    component: () => import('@/views/storefront/mobile/ProductInfo.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/storefront/mobile/:storeID/checkout',
    name: 'storefront-mobile-checkout',
    component: () => import('@/views/storefront/mobile/Checkout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/storefront/mobile/:storeID/invoice',
    name: 'storefront-mobile-invoice',
    component: () => import('@/views/storefront/mobile/Checkout.vue'),
    meta: {
      layout: 'full',
    },
  },

  // web store
  {
    path: '/storefront/web/:storeID',
    name: 'storefront-web',
    component: () => import('@/views/storefront/web-v2/Products.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/storefront/web/:storeID/checkout',
    name: 'storefront-web-checkout',
    component: () => import('@/views/storefront/web-v2/Checkout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/storefront/web/:storeID/invoice',
    name: 'storefront-web-invoice',
    component: () => import('@/views/storefront/web-v2/InvoiceCallback.vue'),
    meta: {
      layout: 'full',
    },
  },

  // pay old version
  {
    path: '/pay/generic-topup',
    alias: ['/pay/pool-topup', '/pay/prefund-topup', '/pay/subscription-payment'],
    name: 'generic-payment-page',
    component: () => import('@/views/paymentpage/genericPaymentComponent.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pay/bill/:uuid',
    name: 'bill-payment',
    component: () => import('@/views/paymentpage/bill.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  //   path: '/pay/collections/:uuid',
  //   name: 'payment-page',
  //   component: () => import('@/views/paymentpage/pay.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/pay/invoice/:invoiceId',
    name: 'customer-bills-page',
    component: () => import('@/views/invoicepage/pay.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pay/thankyou',
    name: 'payment-thank-you-page',
    component: () => import('@/views/paymentpage/thankyou.vue'),
    meta: {
      layout: 'full',
    },
  },

  // default routing 404
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
