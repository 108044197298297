import { APP_SETTINGS } from '@/common/config'
import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import SystemHelper from '@/common/systemHelper'

export const SET_ENDPOINT_ERROR = 'setEndpointError'
export const UPDATE_ENDPOINT_ERROR = 'updateEndpointError'
export const CLEAR_ENDPOINT_ERROR = 'clearEndpointError'

export const CREATE_PAYMENT_VIU = 'createPayment'
export const MANUAL_PREFUND_VIRTUAL_ACCOUNT = 'manualPrefundVirtualAccount'
export const CREATE_PAYMENT_BILL = 'createaPaymentBill'
export const CREATE_PAYMENT_PREFUND = 'createPaymentPrefund'
export const CREATE_SUBSCRIPTION_PAYMENT = 'createSubscriptionPayment'
export const CREATE_PAYMENT_TRANSACTION_POOL = 'createPaymentTransactionPool'

export const GET_PAYMENT_SERVICES = 'getPaymentServices'
export const GET_SELECTED_PAYMENT_SERVICES = 'getSelectedPaymentServices'

export const SET_PAYMENT_SERVICES = 'setPaymentServices'
export const SET_SELECTED_PAYMENT_SERVICES = 'setSelectedPaymentServices'
export const GET_COLLECTION_BY_UUID = 'getCollectionByUuid'
export const SET_COLLECTION_BY_UUID = 'setCollectionByUuid'

export const GET_PAYMENT_RECEIPT_PUBLIC = 'getPaymentReceiptPublic'
export const SET_PAYMENT_RECEIPT_PUBLIC = 'setPaymentReceiptPublic'

export const SET_PAYMENT_SWITCH_RESPONSE = 'setPaymentSwitchResponse'

export const GENERATE_BILL_ID = 'generateBillId'
export const SET_GENERATED_BILL_ID = 'setGeneratedBillId'

export default {
  namespaced: true,
  state: {
    paymentServicesList: null,
    selectedPaymentServicesList: null,
    collectionDetails: null,
    paymentSwitchResponse: null,
    paymentReceiptData: null,
    endpointError: null,
    generatedBillId: '',
  },
  getters: {
    getGeneratedBillId: state => {
      const currentState = state.generatedBillId

      if (currentState) {
        return currentState
      }
      return ''
    },
    getEndpointError: state => {
      const currentState = state.endpointError

      if (currentState) {
        const error = currentState
        return error
      }
      return null
    },
    getPaymentReceiptPublic: state => {
      const currentState = state.paymentReceiptData
      if (currentState) {
        const list = currentState

        return list
      }
      return {}
    },
    getPaymentSwitchResponse: state => {
      const currentState = state.paymentSwitchResponse

      if (currentState) {
        const list = currentState

        return list
      }
      return {}
    },
    paymentServiceLisOpts: state => {
      const currentState = state.paymentServicesList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          const newArray = list.map(item => ({ img: item.unique_reference, id: item.payment_service_id, name: item.name }))
          return newArray
        }
      }
      return []
    },
    getSelectedPaymentServicesList: state => {
      const currentState = state.selectedPaymentServicesList

      if (currentState) {
        const list = currentState.list.data

        return list
      }
      return {}
    },
    getSelectedPaymentServicesChannelOpts: state => {
      function groupTitle(title) {
        let translated
        switch (title) {
          case 'CRYPTOCURRENCY':
            translated = 'Cryptocurrency'
            break
          case 'GLOBAL_CARD_PAYMENT':
            translated = 'Credit Card'
            break
          case 'WEB_PAYMENT':
            translated = 'Online Payment'
            break
          case 'DIGITAL_PAYMENT':
            translated = 'E-Wallet'
            break
          case 'BUY_NOW_PAY_LATER':
            translated = 'Buy Now Pay Later'
            break
          case 'SWITCH_PAYPAL':
            translated = 'Paypal'
            break
          default:
            translated = title
        }

        return translated
      }

      const currentState = state.selectedPaymentServicesList

      if (currentState) {
        const list = currentState.list.data

        console.log(list)

        const childKeys = Object.keys(list)

        const mergedList = []

        for (const key in childKeys) {
          if (childKeys.length > 0) {
            mergedList.push({ keyName: childKeys[key], name: groupTitle(childKeys[key]), id: parseInt(key, 10) + 1 })
            console.log(childKeys[key])
          }
        }
        return mergedList
      }
      return []
    },
    getSelectedServiceByPaymentChannelOpts: state => key => {
      console.log(key)
      const currentState = state.selectedPaymentServicesList

      if (currentState) {
        const list = currentState.list.data

        return list[key]
      }
      return []
    },
    getPaymentServiceNameById: state => id => {
      const currentState = state.paymentServicesList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          const search = list.find(item => item.payment_service_id === id)
          return search
        }
      }
      return []
    },
    paymentServicesList: state => {
      const currentState = state.paymentServicesList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.record_status_id === 1)
        }
        return []
      }
      return []
    },
    paymentServicesOpts: state => {
      const currentState = state.paymentServicesList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.record_status_id === 1)
        }
      }
      return []
    },
    paymentServicesListByGroupCode: state => groupCode => {
      const currentState = state.paymentServicesList

      if (currentState) {
        const list = currentState.list.data

        if (Array.isArray(list) && list.length) {
          return list.filter(el => el.group_code === groupCode)
        }
      }
      return []
    },
  },
  mutations: {
    [SET_ENDPOINT_ERROR](state, val) {
      state.endpointError = val
    },
    [SET_PAYMENT_SERVICES](state, val) {
      state.paymentServicesList = val
    },
    [SET_SELECTED_PAYMENT_SERVICES](state, val) {
      state.selectedPaymentServicesList = val
    },
    [SET_PAYMENT_SWITCH_RESPONSE](state, val) {
      state.paymentSwitchResponse = val
    },
    [SET_COLLECTION_BY_UUID](state, val) {
      state.paymentSwitchResponse = val
    },
    [SET_PAYMENT_RECEIPT_PUBLIC](state, val) {
      state.paymentReceiptData = val
    },
    [SET_GENERATED_BILL_ID](state, val) {
      state.generatedBillId = val
    },
  },
  actions: {
    [UPDATE_ENDPOINT_ERROR](context, payload) {
      context.commit(SET_ENDPOINT_ERROR, payload)
    },
    [CLEAR_ENDPOINT_ERROR](context) {
      context.commit(SET_ENDPOINT_ERROR, null)
    },
    [GET_SELECTED_PAYMENT_SERVICES](context, payload) {
      const { bankType } = payload
      const { invoiceUuid } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/selected-list-payment-services/${bankType}?_invoice_uuid=${invoiceUuid}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              console.log(res.data.data.list.data)
              context.commit(SET_SELECTED_PAYMENT_SERVICES, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            context.commit(SET_ENDPOINT_ERROR, { endpoint: GET_PAYMENT_SERVICES, errorObj: err.toJSON() })
            // SystemHelper.handleCatch()
            // reject(err)
          })
      })
    },
    [GET_COLLECTION_BY_UUID](context, payload) {
      const { collectionUuid } = payload
      console.log(payload)
      console.log(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${collectionUuid}/leanpay-bills`)
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}collections/${collectionUuid}/leanpay-bills`)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_COLLECTION_BY_UUID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res, true)
              resolve(res)
            }
          })
          .catch(err => {
            context.commit(SET_ENDPOINT_ERROR, { endpoint: GET_COLLECTION_BY_UUID, errorObj: err.toJSON() })
          })
      })
    },
    [CREATE_PAYMENT_VIU](context, payload) {
      let appendInvoiceID
      if (payload.invoice_no) {
        appendInvoiceID = `?invoice_no=${payload.invoice_no}`
      } else {
        appendInvoiceID = ''
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/create-payment-viu${appendInvoiceID}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_SWITCH_RESPONSE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_PAYMENT_PREFUND](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/prefund-virtual-pool/${payload.invoice_no}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_SWITCH_RESPONSE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_SUBSCRIPTION_PAYMENT](context, payload) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-underscore-dangle
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/paid-bill-payment?_id=${payload._id}&invoice_no=${payload.invoice_no}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_SWITCH_RESPONSE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [MANUAL_PREFUND_VIRTUAL_ACCOUNT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/manual-prefund-virtual-account?invoice_no=${payload.invoice_no}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_SWITCH_RESPONSE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    /*
    // SYSTEM CONSTANTS PARAMETER
    CASH_OUT_POOL: 4
    COLLECTION_POOL : 2
    LOYALTY_POINT: 3
    PREFUND_POOL: 6
    THRESHOLD_POOL: 5
    TRANSACTION_POOL: 1
    */
    [CREATE_PAYMENT_TRANSACTION_POOL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/add-fund-transaction-pool?invoice_no=${payload.invoice_no}&pool_type=${payload.poolType}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_SWITCH_RESPONSE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [CREATE_PAYMENT_BILL](context, payload) {
      let appendInvoiceID
      if (payload.invoice_no) {
        appendInvoiceID = `?_invoice=${payload.invoice_no}`
      } else {
        appendInvoiceID = ''
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/payment-bill/_invoice${appendInvoiceID}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_SWITCH_RESPONSE, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GET_PAYMENT_SERVICES](context, payload) {
      const { bankType } = payload
      return new Promise((resolve, reject) => {
        axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/list-payment-services/${bankType}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_SERVICES, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            context.commit(SET_ENDPOINT_ERROR, { endpoint: GET_PAYMENT_SERVICES, errorObj: err.toJSON() })
            // SystemHelper.handleCatch()
            // reject(err)
          })
      })
    },
    [GET_PAYMENT_RECEIPT_PUBLIC](context, payload) {
      let suppressAlert = false
      const invoiceNo = payload.invoice_no
      if (payload.suppressAlert) {
        suppressAlert = payload.suppressAlert
      }

      return new Promise((resolve, reject) => {
        // axiosIns.get(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}customer-bills/${invoiceNo}/leanpay-bills`, payload)

        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}payment-services/switch/manual-checking-transaction?invoice_no=${invoiceNo}`, payload)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_PAYMENT_RECEIPT_PUBLIC, res.data.data)
              if (!suppressAlert) {
                SystemHelper.successHandler(res)
              }
              resolve(res)
            } else {
              if (!suppressAlert) {
                SystemHelper.errorHandler(res)
              }
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    [GENERATE_BILL_ID](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.BASE_URL + APP_SETTINGS.API_VERSION_NUM}jwt/generate/bill-id`)
          .then(res => {
            if (res.data.response_code === 2000) {
              context.commit(SET_GENERATED_BILL_ID, res.data.data)
              SystemHelper.successHandler(res)
              resolve(res)
            } else {
              SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
  },
}
